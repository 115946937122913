import { render } from "./EmailChange.vue?vue&type=template&id=301bb59c"
import script from "./EmailChange.ts?vue&type=script&lang=ts"
export * from "./EmailChange.ts?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "301bb59c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('301bb59c', script)) {
    api.reload('301bb59c', script)
  }
  
  module.hot.accept("./EmailChange.vue?vue&type=template&id=301bb59c", () => {
    api.rerender('301bb59c', render)
  })

}

script.__file = "src/views/Profile/EmailChange.vue"

export default script