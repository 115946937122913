<template>
  <main class="wallet-transactions">
    <ModalHeader
      :title="$t('pages.profile.walletTransactions.title')"
      :dir="ModalHeaderDirection.Ltr"
      relative
      @close="back"
    >
      <template #close-icon>
        <icon
          :type="IconType.Contour"
          :name="IconName.ArrowBack"
          :size="Size.S"
        />
      </template>
    </ModalHeader>

    <div
      v-if="items.length > 0"
      class="wallet-transactions__transactions"
    >
      <wallet-transaction
        v-for="item in items"
        :key="item.id"
        :transaction="item"
      />
    </div>
  </main>
</template>

<script lang="ts" src="./WalletTransactions.ts" />
<style lang="scss" src="./walletTransactions.scss" />
