import { render } from "./ActiveServices.vue?vue&type=template&id=5c60efd4"
import script from "./ActiveServices.ts?vue&type=script&lang=ts"
export * from "./ActiveServices.ts?vue&type=script&lang=ts"

import "./activeServices.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5c60efd4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5c60efd4', script)) {
    api.reload('5c60efd4', script)
  }
  
  module.hot.accept("./ActiveServices.vue?vue&type=template&id=5c60efd4", () => {
    api.rerender('5c60efd4', render)
  })

}

script.__file = "src/views/Profile/components/ActiveServices/ActiveServices.vue"

export default script