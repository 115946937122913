<template>
  <div class="tag-list" ref="tagListRef">
    <div
      :class="[
        { 'tag-list__title': true },
        { 'tag-list__title--error': hasError }
      ]"
    >
      {{ title }}
    </div>
    <div class="tag-list__tags">
      <span
        v-for="(value, key) in map"
        :key="key"
        :class="[
          { 'tag-list__tag': true },
          { 'tag-list__tag--circle': circle },
          { 'tag-list__tag--selected': isSelected(isArray ? value : key) },
          { [`tag-list__tag--size-${size}`]: size }
        ]"
        @click="handleClick(isArray ? value : key)"
      >
        <span v-if="$slots.marker" class="tag-list__marker">
          <slot
            :key="key"
            :value="value"
            name="marker"
          />
        </span>
        <slot
          :key="key"
          :value="value"
        />
      </span>
    </div>
  </div>
</template>

<script lang="ts" src="./TagList.ts" />
<style lang="scss" src="./tagList.scss" />
