import { render } from "./Favorites.vue?vue&type=template&id=2e01b49f"
import script from "./Favorites.ts?vue&type=script&lang=ts"
export * from "./Favorites.ts?vue&type=script&lang=ts"

import "./favorites.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "2e01b49f"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2e01b49f', script)) {
    api.reload('2e01b49f', script)
  }
  
  module.hot.accept("./Favorites.vue?vue&type=template&id=2e01b49f", () => {
    api.rerender('2e01b49f', render)
  })

}

script.__file = "src/views/Catalog/Favorites/Favorites.vue"

export default script