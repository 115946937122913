import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { IconName, IconType } from 'components/Icon';
import { Size } from 'core/styles';

const TelegramUrl = process.env.VUE_APP_TELEGRAM_BOT_LINK;

const ToTelegram = defineComponent({
  setup() {
    const store = useStore();

    const tgToken = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.TgToken}`]);

    return {
      tgToken,
      TelegramUrl,

      IconName,
      IconType,
      Size,
    };
  },
});

export default ToTelegram;
