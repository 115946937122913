<template>
  <auth-layout
    show-back-btn
    :back-btn-to="backPath"
  >
    <div
      class="mb-4"
    >
      <div class="py-8" />
      <img src="@/assets/images/svg/email.svg">
      <div class="text-left text-dark-grey mt-8">
        {{ $t('pages.successEmailConfirm.message') }}
      </div>
      <btn
        w="100%"
        class="auth-layout-form__submit mt-6"
        :to="backPath"
      >
        {{ $t('pages.successEmailConfirm.submit') }}
      </btn>
    </div>
  </auth-layout>
</template>

<script lang="ts" src="./SuccessEmailConfirm.ts" />
