import { render } from "./Wallet.vue?vue&type=template&id=205d3079"
import script from "./Wallet.ts?vue&type=script&lang=ts"
export * from "./Wallet.ts?vue&type=script&lang=ts"

import "./wallet.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "205d3079"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('205d3079', script)) {
    api.reload('205d3079', script)
  }
  
  module.hot.accept("./Wallet.vue?vue&type=template&id=205d3079", () => {
    api.rerender('205d3079', render)
  })

}

script.__file = "src/views/Profile/Wallet.vue"

export default script