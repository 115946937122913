import { defineComponent, PropType } from 'vue';

import { useI18n } from 'vue-i18n';

import { ThemeColor } from 'api/users/models/user/enums/ThemeColor';
import { MessageType } from './enums/MessageType';

export default defineComponent({
  props: {
    type: {
      type: String as PropType<MessageType>,
      required: true,
    },
    backgroundColor: {
      type: String as PropType<ThemeColor>,
      required: false,
      default: 'white',
    },
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
      MessageType,
    };
  },
});
