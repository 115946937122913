import { computed, defineComponent } from 'vue';
import AuthLayout from 'layouts/AuthLayout';
import { useRoute } from 'vue-router';

const SuccessEmailConfirm = defineComponent({
  components: {
    AuthLayout,
  },
  setup() {
    const route = useRoute();
    const backPath = computed(() => route.redirectedFrom?.path || '/');

    return {
      backPath,
    };
  },
});

export default SuccessEmailConfirm;
