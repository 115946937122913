<template>
  <main>
    <ModalHeader
      :title="t('pages.profile.styling.title')"
      :dir="ModalHeaderDirection.Ltr"
      relative
      @close="back"
    >
      <template #close-icon>
        <icon
          :type="IconType.Contour"
          :name="IconName.ArrowBack"
          :size="Size.S"
        />
      </template>
    </ModalHeader>

    <txt
      class="text"
      tag="div"
      :type="TxtType.Heading3"
    >
      {{ t('pages.profile.styling.choose') }}
    </txt>
    <div class="color-list">
      <div
        v-for="(item, index) in colors"
        :key="index"
        class="color-list__color"
        @click="changeThemeColor(item)"
      >
        <div
          class="color-list__color-border"
          :style="{
            borderColor:
              activeThemeColor === item
                ? `var(--${item})`
                : 'var(--backgroundColor)',
          }"
        >
          <div
            class="color-list__color-circle"
            :style="{ backgroundColor: `var(--${item})` }"
          />
        </div>
        <txt
          class="color-list__color-text"
          tag="div"
          :weight="TxtWeight.Medium"
        >
          {{ t('colors.' + item) }}
        </txt>
      </div>
    </div>

    <div class="chat-preview">
      <Logo />
      <Message
        :type="MessageType.Outgoing"
        :backgroundColor="activeThemeColor"
      />
      <Message :type="MessageType.Incoming" />
    </div>
  </main>
</template>

<script lang="ts" src="./Styling.ts"></script>
<style lang="scss" src="./styling.scss"></style>
