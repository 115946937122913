<template>
  <main class="wallet-refill">
    <ModalHeader
      :title="t('pages.profile.wallet.title')"
      :dir="ModalHeaderDirection.Ltr"
      relative
      @close="back"
    >
      <template #close-icon>
        <icon
          :type="IconType.Contour"
          :name="IconName.ArrowBack"
          :size="Size.S"
        />
      </template>
    </ModalHeader>

    <div class="wallet-refill-content">
      <div class="wallet-refill-card">
        <h3>{{ t('pages.profile.wallet.balance') }}</h3>
        <div class="wallet-refill-card__balance">
          <span class="wallet-refill-card__balance__amount">{{ formatCurrency(balance) }}</span>
          <template v-if="amount">
            <span class="wallet-refill-card__divider">
              <icon
                :type="IconType.Contour"
                :name="IconName.ArrowRight"
                :size="Size.M"
              />
            </span>
            <span class="wallet-refill-card__balance__amount wallet-refill-card__balance__amount_green">
              {{
                !Number.isNaN(Number(amount))
                  ? formatCurrency(balance + Number(amount))
                  : formatCurrency(balance)
              }}
            </span>
          </template>
        </div>
      </div>

      <div class="pt-6 pb-4">
        {{ $t('pages.profile.walletRefill.amount.label') }}
      </div>
      <textfield
        ref="amountRef"
        v-model.number="amount"
        rt="₽"
        inputmode="numeric"
        class="wallet-refill-amount"
        @update:model-value="onAmountInput"
      />

      <div class="wallet-refill-preset">
        <btn
          v-for="item in presets"
          :key="item"
          :type="BtnType.Text"
          :class="{
            'wallet-refill-preset__item': true,
            'wallet-refill-preset__item_active': activePreset === item.id,
          }"
          @click="selectPreset(item.id, item.amount)"
        >
          {{ formatCurrency(item.amount) }}
        </btn>
      </div>

      <!-- <div class="pt-6 pb-4">
        {{ $t('pages.profile.walletRefill.method.label') }}
      </div>
      <div class="wallet-refill-radio">
        <input
          id="card-method-input"
          name="method-input"
          type="radio"
          checked
        >
        <label for="card-method-input">
          {{ $t('pages.profile.walletRefill.method.card') }}
        </label>
      </div> -->

      <div class="pt-6" />

      <div class="wallet-refill-submit">
        <btn
          w="100%"
          :disabled="isFetching"
          @click="onSubmit"
        >
          {{ t('pages.profile.wallet.topUp') }}
        </btn>
      </div>
    </div>
  </main>
</template>

<script lang="ts" src="./WalletRefill.ts"></script>
<style lang="scss" src="./wallet-refill.scss"></style>
