import { defineComponent, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { useApiErrors } from 'composables/apiErrors';
import {
  USER_STORE_KEY,
  UsersGetter,
  useUsersGetter,
  UsersAction,
} from 'store/users';

import { IconType, IconName } from 'components/Icon';
import { Size } from 'core/styles';
import { ModalHeader, ModalHeaderDirection } from 'components/Modal';
import { TxtType, TxtWeight } from 'components/Txt';
import { ThemeColor, BackgroundColor } from 'api/users/models/user';
import { IAppearance } from 'api/users/models/user/interfaces/IAppearance';
import { RouteNames } from 'router/names';
import Message, { MessageType } from './components/Message';

const Styling = defineComponent({
  name: RouteNames.ProfileSettingsStyling,
  components: {
    ModalHeader,
    Message,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const { showError } = useApiErrors();

    const colors = [
      ThemeColor.Lavender,
      ThemeColor.Red,
      ThemeColor.Blue,
    ];
    const activeThemeColor = computed(() =>
      useUsersGetter<ThemeColor>(UsersGetter.ThemeColor) || ThemeColor.Lavender);

    const changeThemeColor = async (color: ThemeColor) => {
      if (activeThemeColor.value === color) return;
      const appearance: IAppearance = {
        color,
        background: BackgroundColor.Light,
      };
      try {
        await store.dispatch(`${USER_STORE_KEY}/${UsersAction.ChangeTheme}`, appearance);
      } catch (error) {
        showError(error);
      }
    };

    function back() {
      router.back();
    }

    return {
      t,
      colors,
      activeThemeColor,
      changeThemeColor,
      back,

      IconType,
      IconName,
      Size,
      ModalHeaderDirection,
      TxtType,
      TxtWeight,
      MessageType,
    };
  },
});

export default Styling;
