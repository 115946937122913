import { computed, defineComponent } from 'vue';
import Modal, { ModalHeader, ModalHeaderDirection } from 'components/Modal';
import Prompt from 'components/Prompt';
import WalletTransaction from 'views/Profile/components/WalletTransaction';
import { useRouter } from 'vue-router';
import { IconName, IconType } from 'components/Icon';
import { Size } from 'core/styles';
import { IProfile, ITransaction } from 'api/users/models/user';
import { UsersGetter, useUsersGetter } from 'store/users';

const WalletTransactions = defineComponent({
  components: {
    Modal,
    ModalHeader,
    Prompt,
    WalletTransaction,
  },
  setup() {
    const router = useRouter();

    const profile = computed(() => useUsersGetter<IProfile>(UsersGetter.Profile));
    const items = computed<ITransaction[]>(() => profile.value.transactions || []);

    function back() {
      router.back();
    }

    return {
      back,
      items,

      ModalHeaderDirection,
      IconType,
      IconName,
      Size,
    };
  },
});

export default WalletTransactions;
