import { render } from "./PasswordChange.vue?vue&type=template&id=3bc7a5ab"
import script from "./PasswordChange.ts?vue&type=script&lang=ts"
export * from "./PasswordChange.ts?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "3bc7a5ab"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3bc7a5ab', script)) {
    api.reload('3bc7a5ab', script)
  }
  
  module.hot.accept("./PasswordChange.vue?vue&type=template&id=3bc7a5ab", () => {
    api.rerender('3bc7a5ab', render)
  })

}

script.__file = "src/views/Profile/PasswordChange.vue"

export default script