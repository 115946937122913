import { defineComponent, nextTick, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { TxtType } from 'components/Txt';
import { TextfieldStyle } from 'components/Textfield';
import { useRules } from 'composables/rules';

const DeletionReasonPopup = defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['submit', 'update:modelValue'],
  setup(_, { emit }) {
    const { t } = useI18n();
    const chosenReason = ref();
    const otherReason = ref('');

    const reasons = Array.from(Array(4), (_, key) => t(`components.deletionReasonPopup.reasons[${key}]`));

    const { minLength, maxLength, rules } = useRules();
    const reasonRef = ref();

    function validate() {
      if (chosenReason.value >= 0) return true;
      const reasonValid = reasonRef.value.validate();
      if (reasonValid) return true;

      reasonRef.value.focus();
      return false;
    }

    function handleSubmit() {
      if (validate()) {
        if (chosenReason.value >= 0) emit('submit', reasons[chosenReason.value]);
        else emit('submit', otherReason.value);

        emit('update:modelValue', false);
      }
    }

    function handleReasonClick(key: number) {
      chosenReason.value = key;
      if (key === -1) nextTick(() => reasonRef.value.focus());
    }

    return {
      reasonRef,
      reasons,
      otherReason,
      chosenReason,
      handleReasonClick,
      handleSubmit,

      min: minLength(5),
      max: maxLength(1000),
      rules,

      TxtType,
      TextfieldStyle,
    };
  },
});

export default DeletionReasonPopup;
