<template>
  <main
    :class="{
      'user-layout': true,
      'user-layout_no-footer': !!self
    }"
  >
    <icon-btn
      v-if="!self"
      class="user-layout__back"
      :size="Size.M"
      :color="MainColor.White"
      @click="$router.back()"
    >
      <icon
        :type="IconType.Contour"
        :name="IconName.ArrowBack"
        :size="Size.S"
      />
    </icon-btn>
    <icon-btn
      v-if="!self"
      class="user-layout__favorite"
      :size="Size.M"
      :color="favorite ? MainColor.Red : MainColor.White"
      @click="handleAddToFavorite"
    >
      <icon
        :type="IconType.Fill"
        :name="IconName.Heart"
        :size="Size.S"
      />
    </icon-btn>

    <is-majority-wrapper>
      <section
        class="user-layout-photo-viewer"
        @click="onPhotoViewerClick"
      >
        <image-slider
          v-if="user.photos && user.photos.length > 0"
          v-model:active-index="activeImageIndex"
          :items="user.photos"
          :width="400"
          :alt="$t('components.avatar.alt', { n: user.name })"
        />
        <div
          v-else-if="self"
          class="user-layout-photo-viewer__empty"
        >
          <div class="relative">
            <icon
              :type="IconType.Big"
              :name="user.gender === Sex.Male ? IconName.Male : IconName.Female"
              :size="Size.Xxl"
              :color="MainColor.Grey4"
              class="user-layout-photo-viewer__empty__icon"
            />
            <div class="user-layout-photo-viewer__empty__plus">
              <icon
                :type="IconType.Fill"
                :name="IconName.Plus"
                :size="Size.M"
                :color="MainColor.Grey4"
              />
            </div>
          </div>
          <div class="user-layout-photo-viewer__empty__title">
            {{ $t('pages.profile.addPhotoUpload') }}
          </div>
          <div class="user-layout-photo-viewer__empty__subtitle">
            {{ $t('pages.profile.addPhotoSubtitle') }}
          </div>
          <input
            ref="fileInput"
            type="file"
            accept="image/*"
            @change="onFileInputChange"
          >
        </div>
        <div
          v-else
          class="user-layout-photo-viewer__empty user-layout-photo-viewer__empty_profile"
        >
          <icon
            :type="IconType.Big"
            :name="user.gender === Sex.Male ? IconName.Male : IconName.Female"
            :size="Size.Xxl"
            :color="MainColor.Grey4"
            class="user-layout-photo-viewer__empty__icon"
          />
          <div class="user-layout-photo-viewer__empty__title">
            {{ $t('components.avatar.none') }}
          </div>
        </div>
      </section>
    </is-majority-wrapper>

    <section class="user-layout-info">
      <div class="user-layout-info__title">
        <div class="user-layout-info__title-wrapper">
          <txt
            :type="TxtType.Heading1"
            :weight="TxtWeight.Bold"
            style=""
          >
            {{ user.name }}
          </txt>
          <premium-icon
            :paid-params="paidParamKeys"
            class="ml-2"
            size="36px"
          />
        </div>
        <icon
          v-if="user.is_premium"
          :type="IconType.Fill"
          :name="IconName.Premium"
          :size="Size.M"
          color="gold"
        />
      </div>

      <txt
        v-if="!self"
        tag="div"
        :class="{
          'user-layout-info__status': true,
          'user-layout-info__status_online': user.is_online,
        }"
      >
        {{ getUserStatus(user) }}
      </txt>

      <span
        v-if="user.typical_photo"
        class="user-layout-info__typical-photo"
      >
        {{ $t('user.typicalPhoto') }}
      </span>

      <settings-bar
        v-if="self"
        :balance="profile.balance"
        @edit="$emit('clickEdit')"
        @settings="$router.push({ name: 'profile-settings' })"
        @wallet="$router.push({ name: 'profile-wallet' })"
      />

      <to-telegram
        v-if="self"
        class="mb-4"
      />

      <div
        v-if="checkPropertyExist(user.age)"
        class="user-layout-info__row"
      >
        <txt
          tag="div"
          class="user-layout-info__row-title"
          :weight="TxtWeight.Bold"
        >
          {{ $t('pages.form.age') }}
        </txt>

        <txt
          tag="div"
          class="user-layout-info__row-body"
        >
          {{ user.age }}
        </txt>
      </div>

      <div
        v-if="checkPropertyExist(user.city_id)"
        class="user-layout-info__row"
      >
        <txt
          tag="div"
          class="user-layout-info__row-title"
          :weight="TxtWeight.Bold"
        >
          {{ $t('pages.form.city') }}
        </txt>

        <txt
          tag="div"
          class="user-layout-info__row-body"
        >
          {{ getUserCityName(user) }}
        </txt>
      </div>

      <div
        v-if="user.properties && checkPropertyExist(user.properties.purpose)"
        class="user-layout-info__row"
      >
        <txt
          tag="div"
          class="user-layout-info__row-title"
          :weight="TxtWeight.Bold"
        >
          {{ $t('pages.form.purpose') }}
        </txt>

        <txt
          tag="div"
          class="user-layout-info__row-body"
        >
          {{ getPurposeString(user.properties.purpose) }}
        </txt>
      </div>

      <div
        v-if="checkPropertyExist(user.height) || checkPropertyExist(user.weight)"
        class="user-layout-info__row"
      >
        <txt
          tag="div"
          class="user-layout-info__row-title"
          :weight="TxtWeight.Bold"
        >
          {{ $t('pages.form.params') }}
        </txt>

        <txt
          tag="div"
          class="user-layout-info__row-body"
        >
          <template v-if="checkPropertyExist(user.height)">
            {{ $t('user.height', { h: user.height }) }}
          </template>
          <template v-if="checkPropertyExist(user.height) && checkPropertyExist(user.weight)">
            •
          </template>
          <template v-if="checkPropertyExist(user.weight)">
            {{ $t('user.weight', { w: user.weight }) }}
          </template>
        </txt>
      </div>

      <div
        v-if="user.properties && checkPropertyExist(user.properties.hair)"
        class="user-layout-info__row"
      >
        <txt
          tag="div"
          class="user-layout-info__row-title"
          :weight="TxtWeight.Bold"
        >
          {{ $t('pages.form.hairColor') }}
        </txt>

        <txt
          tag="div"
          class="user-layout-info__row-body"
        >
          {{ $tc(`user.hairColor.${user.properties.hair}.${user.gender}`) }}
        </txt>
      </div>

      <template v-if="user.gender === Sex.Female">
        <div
          v-if="user.properties && checkPropertyExist(user.properties.breast)"
          class="user-layout-info__row"
        >
          <txt
            tag="div"
            class="user-layout-info__row-title"
            :weight="TxtWeight.Bold"
          >
            {{ $t('pages.form.breastSize') }}
          </txt>

          <txt
            tag="div"
            class="user-layout-info__row-body"
          >
            {{ $t(`user.breast.${user.properties.breast}`) }}
          </txt>
        </div>

        <div
          v-if="user.properties && checkPropertyExist(user.properties.hips)"
          class="user-layout-info__row"
        >
          <txt
            tag="div"
            class="user-layout-info__row-title"
            :weight="TxtWeight.Bold"
          >
            {{ $t('pages.form.hipType') }}
          </txt>

          <txt
            tag="div"
            class="user-layout-info__row-body"
          >
            {{ $t(`user.hip.${user.properties.hips}`) }}
          </txt>
        </div>
      </template>

      <template v-if="user.gender === Sex.Male">
        <div
          v-if="user.properties && checkPropertyExist(user.properties.activity)"
          class="user-layout-info__row"
        >
          <txt
            tag="div"
            class="user-layout-info__row-title"
            :weight="TxtWeight.Bold"
          >
            {{ $t('pages.form.fieldType') }}
          </txt>

          <txt
            tag="div"
            class="user-layout-info__row-body"
          >
            {{ getActivityString(user.properties.activity) }}
          </txt>
        </div>

        <div
          v-if="user.properties && checkPropertyExist(user.properties.body)"
          class="user-layout-info__row"
        >
          <txt
            tag="div"
            class="user-layout-info__row-title"
            :weight="TxtWeight.Bold"
          >
            {{ $t('pages.form.bodyType') }}
          </txt>

          <txt
            tag="div"
            class="user-layout-info__row-body"
          >
            {{ $t(`user.body.${user.properties.body}`) }}
          </txt>
        </div>
      </template>

      <div
        v-if="user.properties && checkPropertyExist(user.properties.interests)"
        class="user-layout-info__row"
      >
        <txt
          tag="div"
          class="user-layout-info__row-title"
          :weight="TxtWeight.Bold"
        >
          {{ $t('pages.form.interests') }}
        </txt>

        <txt
          tag="div"
          class="user-layout-info__row-body"
        >
          {{ getInterestsString(user.properties.interests) }}
        </txt>
      </div>

      <div
        v-if="checkPropertyExist(user.description)"
        class="user-layout-info__row"
      >
        <txt
          tag="div"
          class="user-layout-info__row-title"
          :weight="TxtWeight.Bold"
        >
          {{ $t('pages.form.about') }}
        </txt>

        <txt
          tag="div"
          class="user-layout-info__row-body"
        >
          {{ user.description }}
        </txt>
      </div>

      <div
        v-for="contact in user.contacts"
        class="user-layout-info__row"
      >
        <txt
          tag="div"
          class="user-layout-info__row-title"
          :weight="TxtWeight.Bold"
        >
          {{ $t(`pages.form.contactsTitle.${contact.type}`) }}
        </txt>

        <txt
          tag="div"
          class="user-layout-info__row-body"
        >
          {{ contact.contact }}
        </txt>
      </div>

      <div
        v-if="self"
        class="user-layout-info__row"
      >
        <btn
          :type="BtnType.Text"
          class="user-layout-info__logout"
          @click="logout"
        >
          {{ $t('user.logout') }}
        </btn>
      </div>

      <div
        v-else-if="isAdmin"
        class="user-layout-info__row"
      >
        <txt
          tag="div"
          class="user-layout-info__row-title"
          :weight="TxtWeight.Bold"
        >
          {{ $t(`admin.actions.title`) }}
        </txt>
        <div style="margin-bottom: 20px; margin-top: 10px;">
          <toggler
            v-model="shadowBan"
            :label="$t(`admin.actions.shadowBan`)"
          />
        </div>
        <div style="margin-bottom: 20px;">
          <textfield
            v-model="blockedAtRef"
            mask="##.##.####"
            type="text"
            inputmode="tel"
            class="edit-form__textfield edit-form__textfield-birthdate"
            :floating-label="$t(`admin.actions.block`)"
          >
            <template #right>
              <btn
                :type="BtnType.Text"
                :disabled="isBlocking"
                @click="block"
              >
                {{ isBlocking ? $t(`admin.actions.savingBlock`) : $t(`admin.actions.saveBlock`) }}
              </btn>
            </template>
          </textfield>
        </div>
        <div style="margin-bottom: 20px;">
          <btn
            :type="BtnType.Text"
            :disabled="isDeleting"
            class="user-layout-info__logout"
            @click="deleteToggle"
          >
            {{ isDeleting ? $t(`admin.actions.deleting`) : $t(`admin.actions.${isDeleted ? 'undelete' : 'delete'}`) }}
          </btn>
        </div>
        <div style="margin-bottom: 20px;">
          <btn
            :type="BtnType.Text"
            class="user-layout-info__logout"
            @click="addOrRemoveAdmin"
          >
            {{ $t(`admin.changeRights.${isUserAdmin ? 'remove' : 'add'}`) }}
          </btn>
        </div>
      </div>
    </section>

    <div
      v-if="!self"
      class="user-layout-footer"
    >
      <btn
        class="user-layout-footer__button"
        w="100%"
        @click="handleSendMessage"
      >
        {{ $t('pages.profile.write') }}
      </btn>

      <btn
        v-if="isAuth"
        class="user-layout-footer__button"
        w="100%"
        :type="BtnType.Secondary"
        @click="isClaimOpen = true"
      >
        {{ $t('layouts.userLayout.toClaim') }}
      </btn>
    </div>

    <photo-viewer
      v-if="user.photos && user.photos.length > 0"
      v-model:active-index="activeImageIndex"
      :show="isViewerOpened"
      :sources="user.photos"

      @close="isViewerOpened = false"
    />

    <modal
      :show="isClaimOpen"
      :header="{
        title: $t('layouts.userLayout.claimReason'),
        dir: ModalHeaderDirection.Rtl,
      }"

      @close="isClaimOpen = false"
    >
      <txt
        v-for="claim in claims"
        :key="claim"
        tag="div"
        :weight="TxtWeight.Medium"
        class="user-claim-item"
        @click="handleClaim(claim)"
      >
        {{ $t(`claims.${claim}`) }}
      </txt>
    </modal>

    <unauthorized-prompt v-model="isAuthorizedModalShow">
      {{ unauthorizedModalTitle }}
    </unauthorized-prompt>
  </main>
</template>

<script lang="ts" src="./UserLayout.ts"></script>
<style lang="scss" src="./user-layout.scss"></style>
