<template>
  <div
    v-if="discount && discount.discount_for_all_expired_at && discount.discount_percent"
    class="package-discount"
  >
    <div class="package-discount__title">
      {{ $t('pages.profile.wallet.packageDiscount.title', { percentage: discount.discount_percent }) }}
    </div>
    <div class="package-discount__msg">
      {{ $t('pages.profile.wallet.packageDiscount.msg') }}
    </div>
    <div class="package-discount__time-wrapper">
      <span class="package-discount__time">
        {{ withZero(remainingTime.hh) }} : {{ withZero(remainingTime.mm) }}
      </span>

      <div class="package-discount-time-label">
        <span class="pr-1">{{ $tc('pages.profile.wallet.packageDiscount.hours', remainingTime.hh) }}</span>
        <span class="pl-1">{{ $tc('pages.profile.wallet.packageDiscount.minutes', remainingTime.mm) }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./PackageDiscount.ts" />
<style lang="scss" src="./packageDiscount.scss" />
