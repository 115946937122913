import { defineComponent, onBeforeMount, onBeforeUnmount, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { IconType, IconName } from 'components/Icon';
import { Size } from 'core/styles';
import { ModalHeader, ModalHeaderDirection } from 'components/Modal';
import { IProfile } from 'api/users/models/user';
import { UsersGetter, useUsersGetter, UsersAction, USER_STORE_KEY } from 'store/users';
import { BtnType } from 'components/Btn';
import { useCurrency } from 'composables/currency';
import { store } from 'store/store';
import { useApiErrors } from 'composables/apiErrors';
import { toastEmitter, ToastType } from 'components/Toasts';
import { PaymentType } from 'core/types';
import { Api } from 'api/api';
import { useFetching } from 'composables/fetching';
import { RouteNames } from 'router/names';

const WalletRefill = defineComponent({
  name: RouteNames.ProfileWalletRefill,
  components: {
    ModalHeader,
  },

  setup() {
    const { t, d } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const { formatCurrency } = useCurrency();
    const { showError } = useApiErrors();

    const presets = [300, 500, 1000, 5000].map((item, i) => ({ id: i + 1, amount: item }));
    const activePreset = ref<number>();
    const amount = ref<number>();
    const amountRef = ref();

    const profile = computed(() => useUsersGetter<IProfile>(UsersGetter.Profile));
    const balance = computed(() => profile.value.balance);

    onBeforeMount(async () => {
      document.body.classList.add('auth-layout--body');
    });
    onBeforeUnmount(() => {
      document.body.classList.remove('auth-layout--body');
    });

    function formatDate(date: string) {
      return d(new Date(date), 'long');
    }

    function back() {
      router.back();
    }

    function onAmountInput() {
      activePreset.value = undefined;
    }

    function selectPreset(id: number, value: number) {
      activePreset.value = id;
      amount.value = value;
    }

    const { isFetching, fetch } = useFetching();
    async function onSubmit() {
      if (amount.value) {
        try {
          if (route.params.type === PaymentType.Card) {
            const response = await fetch(Api.userService.paymentYoupayRequest(amount.value));
            if (response.data.result && response.data.redirect) {
              window.location.href = response.data.redirect;
            } else {
              throw new Error(response.data.message);
            }
          } else {
            await fetch(store.dispatch(`${USER_STORE_KEY}/${UsersAction.WalletEnroll}`, amount.value));
            toastEmitter.emit('toast', {
              type: ToastType.Success,
              message: t('pages.profile.walletRefill.success'),
            });
            activePreset.value = undefined;
            amount.value = undefined;
            if (window.history.state.back === '/profile/wallet') {
              router.go(-1);
            } else {
              router.replace({ name: 'profile-wallet' });
            }
          }
        } catch (error) {
          const amountError = (error as any).response?.data?.errors?.amount?.[0];
          showError(amountError ? new Error(amountError) : error);
        }
      } else {
        amountRef.value?.focus();
      }
    }

    return {
      t,

      isFetching,
      amount,
      amountRef,
      presets,
      activePreset,
      balance,

      formatDate,
      formatCurrency,
      back,
      onAmountInput,
      selectPreset,
      onSubmit,

      IconType,
      IconName,
      Size,
      ModalHeaderDirection,
      BtnType,
    };
  },
});

export default WalletRefill;
