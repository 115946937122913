import { computed, defineComponent, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import AuthLayout from 'layouts/AuthLayout';
import Stepper from 'components/Stepper';
import CodeInput from 'components/CodeInput';
import { BtnType } from 'components/Btn';
import { IconType, IconName } from 'components/Icon';
import { Size } from 'core/styles';
import { Api } from 'api/api';
import { useApiErrors } from 'composables/apiErrors';
import { useRules } from 'composables/rules';
import { RouteNames } from 'router/names';
import { useFetching } from 'composables/fetching';

const PasswordChange = defineComponent({
  name: RouteNames.ProfilePasswordChange,
  components: {
    AuthLayout,
    Stepper,
    CodeInput,
  },

  setup() {
    const { t } = useI18n();
    const { showError } = useApiErrors();

    const success = ref(false);

    const model = reactive<{
      currentPassword: string
      password: string
      passwordConfirm: string
    }>({
      currentPassword: '',
      password: '',
      passwordConfirm: '',
    });
    const shouldValidate = ref(false);
    const currentPasswordRef = ref();
    const passwordRef = ref();
    const passwordConfirmRef = ref();
    const showCurrentPassword = ref(false);
    const showPassword = ref(false);
    const showConfirmPassword = ref(false);

    function validate() {
      shouldValidate.value = true;

      if (
        !currentPasswordRef.value
        || !passwordRef.value
        || !passwordConfirmRef.value) return false;
      if (!passwordRef.value || !passwordConfirmRef.value) return false;
      const currentPasswordValidation = currentPasswordRef.value.validate();
      const passwordValidation = passwordRef.value.validate();
      const passwordConfirmValidation = passwordConfirmRef.value.validate();
      return currentPasswordValidation
        && passwordValidation
        && passwordConfirmValidation;
    }

    const { isFetching, fetch } = useFetching();

    async function onSubmit() {
      if (!isFetching.value && validate()) {
        try {
          const { data } = await fetch(Api.userService.changePassword(model.password, model.passwordConfirm, model.currentPassword));

          if (data.result) {
            success.value = true;
          } else {
            throw new Error(t('errors.api.error'));
          }
        } catch (error) {
          showError(error);
        }
      }
    }

    const { rules, passwordConfirm } = useRules();
    return {
      rules,
      confirmPassword: passwordConfirm(computed(() => model.password)),
      showCurrentPassword,
      showPassword,
      showConfirmPassword,
      success,
      shouldValidate,
      currentPasswordRef,
      passwordRef,
      passwordConfirmRef,
      model,
      onSubmit,
      isFetching,

      BtnType,
      IconType,
      IconName,
      Size,
    };
  },
});

export default PasswordChange;
