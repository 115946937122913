<template>
  <div class="wallet-transaction">
    <div class="wallet-transaction__row">
      <div class="wallet-transaction__icon">
        <icon
          :name="icon.name"
          :type="IconType.Fill"
          :color="icon.color"
          :size="Size.Xs"
        />
      </div>
      <txt :color="isFailed && MainColor.Red || undefined">
        {{ transaction.description }}
      </txt>
      <div class="flex-1" />
      <txt :color="isFailed && MainColor.Red || undefined" class="wallet-transaction__text wallet-transaction__text--shrink-0">
        {{ formatCurrency(transaction.amount, true) }}
      </txt>
    </div>
    <div class="wallet-transaction__row wallet-transaction__row--margin">
      <txt
        :color="MainColor.Grey5"
        :type="TxtType.SmallText"
        class="wallet-transaction__text wallet-transaction__text--shrink-0"
      >
        {{ formatDate(transaction.created_at) }}
      </txt>

      <txt
        v-if="isReferShows"
        :color="MainColor.Grey5"
        :type="TxtType.SmallText"
        class="wallet-transaction__text"
      >
        {{ transaction.refer }}
      </txt>
    </div>
  </div>
</template>

<script lang="ts" src="./WalletTransaction.ts" />
<style lang="scss" src="./walletTransaction.scss" />
