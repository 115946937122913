import { computed, defineComponent } from 'vue';
import { ModalHeader, ModalHeaderDirection } from 'components/Modal';
import { IconName, IconType } from 'components/Icon';
import { useRouter } from 'vue-router';
import { Size } from 'core/styles';
import Toggler from 'components/Toggler/Toggler.vue';
import { INotificationSettings } from 'api/notifications/models';
import { useStore } from 'vuex';
import { NOTIFICATIONS_STORE_KEY } from 'store/notifications/constants';
import { NotificationsAction, NotificationsGetter } from 'store/notifications';
import { useApiErrors } from 'composables/apiErrors';
import { RouteNames } from 'router/names';

const NotificationSettings = defineComponent({
  name: RouteNames.ProfileNotificationSettings,
  components: {
    ModalHeader,
    Toggler,
  },
  setup() {
    const store = useStore();
    const { back } = useRouter();
    const { showError } = useApiErrors();

    const notificationSettings = computed<INotificationSettings>({
      get() {
        return store.getters[`${NOTIFICATIONS_STORE_KEY}/${NotificationsGetter.NotificationSettings}`];
      },
      async set(value) {
        try {
          await store.dispatch(`${NOTIFICATIONS_STORE_KEY}/${NotificationsAction.ChangeNotificationSettings}`, value);
        } catch (e) {
          showError(e);
        }
      },
    });

    const unreadMessages = computed<boolean>({
      get: () => notificationSettings.value.unread_messages,
      set: (val: boolean) => { notificationSettings.value = { ...notificationSettings.value, unread_messages: val }; },
    });
    const userActions = computed<boolean>({
      get: () => notificationSettings.value.users_actions,
      set: (val: boolean) => { notificationSettings.value = { ...notificationSettings.value, users_actions: val }; },
    });
    const payments = computed<boolean>({
      get: () => notificationSettings.value.payments,
      set: (val: boolean) => { notificationSettings.value = { ...notificationSettings.value, payments: val }; },
    });

    return {
      unreadMessages,
      userActions,
      payments,

      back,

      ModalHeaderDirection,
      IconType,
      IconName,
      Size,
    };
  },
});

export default NotificationSettings;
