
import { IconName, IconType } from 'components/Icon';
import { TxtType, TxtWeight } from 'components/Txt';
import { MainColor, Size } from 'core/styles';
import { defineComponent } from 'vue';

const InactiveUser = defineComponent({
    props: {
        title: {
            type: String,
            required: true,
        },
    },
    setup() {
        return {
            IconType,
            IconName,
            Size,
            MainColor,
            TxtType,
            TxtWeight,
        };
    },
});
export default InactiveUser;
