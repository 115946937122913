<template>
  <short-popup
    :model-value="modelValue"
    class="deletion-reason-popup"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <txt
      :type="TxtType.Heading4"
      class="deletion-reason-popup__title"
    >
      {{ $t('components.deletionReasonPopup.title') }}
    </txt>
    <ul class="deletion-reason-popup__ul">
      <li
        v-for="(reason, key) in reasons"
        class="deletion-reason-popup__li"
        :class="{ 'deletion-reason-popup__li--active': chosenReason === key }"
        @click="handleReasonClick(key)"
      >
        {{ reason }}
      </li>
      <li
        class="deletion-reason-popup__li deletion-reason-popup__li--other"
        :class="{ 'deletion-reason-popup__li--active': chosenReason === -1 }"
        @click="handleReasonClick(-1)"
      >
        <span>
          {{ $t('components.deletionReasonPopup.other') }}
        </span>

        <textfield
          v-if="chosenReason === -1"
          ref="reasonRef"
          v-model="otherReason"
          :rules="[rules.required, min, max]"
          class="deletion-reason-popup__textfield"
          :label="$t('components.deletionReasonPopup.otherLabel')"
          :placeholder="$t('components.deletionReasonPopup.otherLabel')"
          :style="TextfieldStyle.Usual"
        />
      </li>
    </ul>
    <div class="deletion-reason-popup__btn-wrapper">
      <btn
        class=""
        w="100%"
        @click="handleSubmit"
      >
        {{ $t('components.deletionReasonPopup.submit') }}
      </btn>
    </div>
  </short-popup>
</template>

<script lang="ts" src="./DeletionReasonPopup.ts" />
<style lang="scss" src="./deletionReasonPopup.scss" />
