import { render } from "./PackageDiscount.vue?vue&type=template&id=1d721fe6"
import script from "./PackageDiscount.ts?vue&type=script&lang=ts"
export * from "./PackageDiscount.ts?vue&type=script&lang=ts"

import "./packageDiscount.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "1d721fe6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1d721fe6', script)) {
    api.reload('1d721fe6', script)
  }
  
  module.hot.accept("./PackageDiscount.vue?vue&type=template&id=1d721fe6", () => {
    api.rerender('1d721fe6', render)
  })

}

script.__file = "src/views/Profile/components/PackageDiscount/PackageDiscount.vue"

export default script