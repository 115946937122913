import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import { IProfilePaidPackage } from 'api/users/models/paidPackages/interfaces';
import { USER_STORE_KEY, UsersGetter, useUsersGetter } from 'store/users';
import { Nullable } from 'core/types';
import { IHighlight, ITop, ThemeColor } from 'api/users/models/user';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import { TxtType, TxtWeight } from 'components/Txt';

const ActiveServices = defineComponent({
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const boughtPackages =
      computed<IProfilePaidPackage[]>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.ProfilePaidPackages}`]);

    const raiseInfo = computed<Nullable<ITop>>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.RaiseInfo}`]);
    const highlightInfo =
      computed<Nullable<IHighlight>>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]?.highlight);

    const formatDate = (str: string) => {
      if (!str) return null;
      const date = new Date(str);
      const now = new Date();
      if (date.getUTCFullYear() === now.getUTCFullYear()) {
        return dayjs(date).format('DD.MM');
      }
      if (date.getUTCFullYear() > now.getUTCFullYear() + 50) return null;
      return dayjs(date).format('DD.MM.YYYY');
    };

    const activeServices = computed(() => {
      const res: { title: string, date: Nullable<string> }[] = [];
      boughtPackages.value.forEach((p) => {
        res.push({
          title: t('components.activeServices.package', { package: p.title }),
          date: formatDate(p.expired_at),
        });
      });

      if (raiseInfo.value) {
        res.push({
          title: t('components.activeServices.raiseProfile'),
          date: formatDate(raiseInfo.value.expired_at),
        });
      }
      if (highlightInfo.value) {
        res.push({
          title: t('components.activeServices.highlight'),
          date: formatDate(highlightInfo.value.expired_at),
        });
      }
      return res.map((i) => {
        if (!i.date) return `${i.title} ${t('components.activeServices.forever')}`;
        return `${i.title} ${t('components.activeServices.date', { date: i.date })}`;
      });
    });

    const activeThemeColor = computed(() =>
      useUsersGetter<ThemeColor>(UsersGetter.ThemeColor) || ThemeColor.Lavender);

    return {
      activeServices,
      activeThemeColor,
      TxtWeight,
      TxtType,
    };
  },
});

export default ActiveServices;
