<template>
  <user-layout
    v-if="user && isUserActive"
    claim
    :favorite="isFavorite"
    :user="user"

    @clickFavorite="handleFavorite"
    @openMessanger="handleOpenMessages"
    @update-user="updateUser"
  />
  <InactiveUser v-else-if="!isFetching" :title="inactiveUserStateTitle" />
</template>

<script lang="ts" src="./User.ts"></script>
