import { render } from "./Filter.vue?vue&type=template&id=5d9b502f"
import script from "./Filter.ts?vue&type=script&lang=ts"
export * from "./Filter.ts?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5d9b502f"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5d9b502f', script)) {
    api.reload('5d9b502f', script)
  }
  
  module.hot.accept("./Filter.vue?vue&type=template&id=5d9b502f", () => {
    api.rerender('5d9b502f', render)
  })

}

script.__file = "src/views/Catalog/Filter/Filter.vue"

export default script