import { render } from "./InactiveUser.vue?vue&type=template&id=52057220"
import script from "./InactiveUser.vue?vue&type=script&lang=ts"
export * from "./InactiveUser.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "52057220"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('52057220', script)) {
    api.reload('52057220', script)
  }
  
  module.hot.accept("./InactiveUser.vue?vue&type=template&id=52057220", () => {
    api.rerender('52057220', render)
  })

}

script.__file = "src/views/User/InactiveUser.vue"

export default script