import { render } from "./TagList.vue?vue&type=template&id=43efad01"
import script from "./TagList.ts?vue&type=script&lang=ts"
export * from "./TagList.ts?vue&type=script&lang=ts"

import "./tagList.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "43efad01"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('43efad01', script)) {
    api.reload('43efad01', script)
  }
  
  module.hot.accept("./TagList.vue?vue&type=template&id=43efad01", () => {
    api.rerender('43efad01', render)
  })

}

script.__file = "src/components/TagList/TagList.vue"

export default script