import { ContactType } from 'api/users/models/contact';
import { clone } from 'ramda';
import {
  BodyType,
  BreastSize,
  FieldType,
  HairColor,
  HipType,
  InterestType, IProfile,
  PurposeType,
  Sex,
} from 'api/users/models/user';
import { useCropper } from 'modules/cropper';

import { BtnType } from 'components/Btn';
import { IconName, IconType } from 'components/Icon';
import { TextfieldStyle } from 'components/Textfield';
import { TxtWeight } from 'components/Txt';
import { MainColor, Size } from 'core/styles';
import dayjs, { Dayjs } from 'dayjs';
import { StoreInjectionKey } from 'store/store';
import { USER_STORE_KEY, UsersAction, UsersGetter, UsersMutation, useUsersGetter } from 'store/users';
import type { PropType } from 'vue';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { toastEmitter, ToastType } from 'components/Toasts';
import Autocomplete from 'components/Autocomplete';
import { useI18n } from 'vue-i18n';
import { useApiErrors } from 'composables/apiErrors';
import AddPhotos from 'views/Profile/components/AddPhotos';
import { useAddPhoto } from 'composables/addPhoto';
import { usePageLoader } from 'composables/pageLoader';
import { useRules } from 'composables/rules';
import { Nullable } from 'core/types';
import TagList from 'components/TagList';
import { CITIES_STORE_KEY, CitiesGetter } from 'store/cities';
import { onBeforeRouteLeave } from 'vue-router';
import AddContacts from '../AddContacts';

const EditForm = defineComponent({
  components: {
    Autocomplete,
    TagList,
    AddPhotos,
    AddContacts,
  },

  props: {
    uploaded: {
      type: Array as PropType<File[]>,
      default: () => [],
    },
    isHeightFocus: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close', 'success'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore<typeof StoreInjectionKey>();

    onBeforeRouteLeave(() => {
      emit('close');
    });

    const { showError: showApiError } = useApiErrors();
    const cities = computed(() => store.getters[`${CITIES_STORE_KEY}/${CitiesGetter.Cities}`]);

    const { rules, minLength } = useRules();

    const profileGetter = useUsersGetter<IProfile>(UsersGetter.Profile);
    const internal = ref<IProfile & { birthdate: Dayjs }>(clone({
      ...profileGetter,
      birthdate: dayjs(profileGetter.birth),
    }));
    const shouldValidate = ref(true);
    const nameRef = ref();
    const aboutRef = ref();
    const cityRef = ref();
    const heightInputRef = ref();
    const weightInputRef = ref();
    const birthdateRef = ref();
    const hairTagsRef = ref();
    const breastTagsRef = ref();
    const hipTagsRef = ref();
    const bodyTagsRef = ref();
    const purposeTagsRef = ref();
    const birthdateLazy = ref('');
    const yearsOld = ref('');

    const profile = computed<Nullable<IProfile>>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]);
    const profilePhotos = computed(() => profile.value?.photos);
    const profilePrivatePhotos = computed(() => profile.value?.private_photos);

    watch(profilePhotos, (v) => {
      if (v) {
        internal.value.photos = v;
      }
    });
    watch(profilePrivatePhotos, (v) => {
      if (v) {
        internal.value.private_photos = v;
      }
    });

    const editPhotoItem = ref<{
      src: string
      name: string
      index: number,
    }>({
      src: '',
      name: '',
      index: -1,
    });
    const isPhotoEdit = ref(false);
    const activeImageIndex = ref(0);
    const emptyFileInput = ref<HTMLInputElement>();
    const fileInput = ref<HTMLInputElement>();

    const locationRequired = computed({
      get: () => store.getters[`${USER_STORE_KEY}/${UsersGetter.GeolocationRequired}`],
      set: (val: boolean) => {
        store.commit(`${USER_STORE_KEY}/${UsersMutation.SetGeolocationRequired}`, val);
      },
    });

    watch(() => internal.value.birthdate, (val) => {
      if (val) {
        birthdateLazy.value = val.format('DD.MM.YYYY');
      }
    }, { immediate: true });

    const birthdate = computed({
      get: () => birthdateLazy.value,
      set: (val: string) => {
        setYearsOld(val);
        birthdateLazy.value = val;
        const isValid = rules.legal(val) === true;
        if (isValid) {
          internal.value.birthdate = dayjs((val.split('.') ?? []).reverse().join('-'));
        }
      },
    });

    const hairColorMap = {
      [HairColor.Blond]: '#FAF0BE',
      [HairColor.Brunette]: '#000000',
      [HairColor.Brown]: '#654321',
      [HairColor.Fair]: '#BB8249',
      [HairColor.Red]: '#FEA742',
      [HairColor.Colorful]: 'linear-gradient(180deg, #FF0000 0%, #FF9900 17.19%, #FFC700 35.42%, #33BF02 51.04%,' +
      '#00E4D7 67.19%, #2067D0 84.9%, #9301D8 100%);',
      [HairColor.None]: 'transparent',
      [HairColor.Blond]: '#FAF0BE',
    };

    const hipMap = {
      [HipType.Small]: IconName.HipSmall,
      [HipType.Middle]: IconName.HipMiddle,
      [HipType.Round]: IconName.HipRound,
    };

    const fieldMap = {
      [FieldType.Finance]: '💵',
      [FieldType.Tourism]: '🏕️',
      [FieldType.Education]: '🎓',
      [FieldType.It]: '💻',
      [FieldType.Medicine]: '💉',
      [FieldType.Trade]: '📠',
      [FieldType.Industry]: '🏭',
      [FieldType.Sport]: '⚽',
      [FieldType.Art]: '🎨',
      [FieldType.Other]: '🙌🏽',
    };

    const bodyMap = {
      [BodyType.Thin]: IconName.Chest1,
      [BodyType.Athletic]: IconName.Chest2,
      [BodyType.Paunchy]: IconName.Chest3,
    };

    const purposeMap = {
      [PurposeType.ForSponsor]: '💰',
      [PurposeType.Relationship]: '💞',
      [PurposeType.Sponsor]: '💸',
      [PurposeType.Looking]: '👀',
      [PurposeType.OpenRelationship]: '🔥',
    };

    const interestsMap = {
      [InterestType.Books]: '📕',
      [InterestType.Movies]: '🎬',
      [InterestType.Music]: '🎸',
      [InterestType.Art]: '🎨',
      [InterestType.Architecture]: '🏢',
      [InterestType.Design]: '◼️',
      [InterestType.ForeignLanguages]: '👅',
      [InterestType.Fashion]: '👠',
      [InterestType.Dancing]: '💃🏻',
      [InterestType.Cooking]: '🍕',
      [InterestType.Astrology]: '🔮',
      [InterestType.Psychology]: '❤️',
      [InterestType.Space]: '✨',
      [InterestType.Sport]: '🏆',
      [InterestType.Extreme]: '⚡️',
      [InterestType.Games]: '🎮',
      [InterestType.Travel]: '🏔',
      [InterestType.Cars]: '🚗',
      [InterestType.Aviation]: '✈️',
      [InterestType.Yachting]: '🛳',
      [InterestType.Business]: '💼',
      [InterestType.Politics]: '🗣',
      [InterestType.Investments]: '💰',
      [InterestType.Technology]: '💻',
      [InterestType.Science]: '🎓',
    };

    watch(locationRequired, (val) => {
      if (val) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(onGetPositionSuccess, onGetPositionError);
        } else {
          showError('Geolocation is not supported by your browser');
          throw new Error('Geolocation is not supported');
        }
      }
    });

    onMounted(() => {
      if (props.isHeightFocus) {
        heightInputRef.value?.focus();
      }
    });

    function showError(message: string) {
      toastEmitter.emit('toast', {
        type: ToastType.Error,
        message,
      });
    }

    function onGetPositionSuccess(position: GeolocationPosition) {
      locationRequired.value = true;
      store.dispatch(`${USER_STORE_KEY}/${UsersAction.PutGeolocationCoords}`, position.coords).catch((e) => showApiError(e));
    }

    function onGetPositionError(error: GeolocationPositionError) {
      locationRequired.value = false;
      switch (error.code) {
        case GeolocationPositionError.PERMISSION_DENIED:
          showError(t('geolocation.PERMISSION_DENIED'));
          break;
        case GeolocationPositionError.POSITION_UNAVAILABLE:
          showError(t('geolocation.POSITION_UNAVAILABLE'));
          break;
        case GeolocationPositionError.TIMEOUT:
          showError(t('geolocation.TIMEOUT'));
          break;
        default:
          showError(t('geolocation.UNKNOWN'));
          throw new Error(`Geolocation is not supported, ${error.message}`);
      }
    }

    const isFetching = ref(false);
    const handleSubmit = async () => {
      if (validate()) {
        isFetching.value = true;
        try {
          await store.dispatch(`${USER_STORE_KEY}/${UsersAction.EditProfile}`, internal.value);
          emit('close');
          emit('success', true);
        } catch (error) {
          showApiError(error);
        } finally {
          isFetching.value = false;
        }
      }
    };

    // todo: refactor this
    function validate() {
      const isMale = internal.value.gender === Sex.Male;
      const isFemale = !isMale;
      shouldValidate.value = true;
      const nameValidation = nameRef.value.validate();
      const aboutValidation = aboutRef.value.validate();
      const cityValidation = cityRef.value.validate();
      const birthdateValidation = birthdateRef.value.validate();
      const weightValidation = weightInputRef.value.validate();
      const heightValidation = heightInputRef.value.validate();
      const hairValidation = hairTagsRef.value.validate();
      const breastValidation = !isFemale || breastTagsRef.value.validate();
      const hipValidation = !isFemale || hipTagsRef.value.validate();
      const bodyValidation = !isMale || bodyTagsRef.value.validate();
      const purposeValidation = purposeTagsRef.value.validate();

      if (!nameValidation) {
        nameRef.value.focus();
      } else if (!birthdateValidation) {
        birthdateRef.value.focus();
      } else if (!cityValidation) {
        cityRef.value.focus();
      } else if (!heightValidation) {
        heightInputRef.value.focus();
      } else if (!weightValidation) {
        weightInputRef.value.focus();
      } else if (!hairValidation) {
        hairTagsRef.value.focus();
      } else if (!breastValidation) {
        breastTagsRef.value.focus();
      } else if (!hipValidation) {
        hipTagsRef.value.focus();
      } else if (!bodyValidation) {
        bodyTagsRef.value.focus();
      } else if (!purposeValidation) {
        purposeTagsRef.value.focus();
      } else if (!aboutValidation) {
        aboutRef.value.focus();
      }

      return nameValidation
        && aboutValidation
        && cityValidation
        && birthdateValidation
        && weightValidation
        && heightValidation
        && hairValidation
        && breastValidation
        && hipValidation
        && bodyValidation
        && purposeValidation;
    }

    function setYearsOld(v: string) {
      const val = (v.split('.') ?? []).reverse().join('-');
      const date = dayjs(val);
      const now = dayjs();
      if (
        date.isValid()
        && date.isBefore(now)
        && date.isAfter('1901-01-01')
        && dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') === val
      ) {
        const old = now.diff(date, 'year');
        yearsOld.value = `${old} ${t('user.age', old)}`;
      } else {
        yearsOld.value = '';
      }
    }

    const { startLoading, endLoading, increment } = usePageLoader();

    const { cropImage } = useCropper();
    const isPhotoLoading = ref(false);
    const { onSave } = useAddPhoto(false, {
      onUploaded: () => {
        endLoading();
        isPhotoLoading.value = false;
      },
      onUploadProgress: (progress) => {
        increment(progress);
      },
      onStartUploading: () => {
        startLoading();
        isPhotoLoading.value = true;
      },
    });

    if (props.uploaded && props.uploaded[0]) {
      cropImage({
        image: URL.createObjectURL(props.uploaded[0]),
        onCrop: onSave,
      });
    }
    watch(() => props.uploaded, (files) => {
      if (files && files[0]) {
        cropImage({
          image: URL.createObjectURL(files[0]),
          onCrop: onSave,
        });
      }
    });

    function handleAddPhoto(event: Event) {
      const target = event.target as HTMLInputElement;
      if (!target) return;
      const _file = target?.files?.[0];
      if (!_file) return;
      cropImage({
        image: URL.createObjectURL(_file),
        onCrop: onSave,
      });
      target.value = '';
    }

    return {
      handleAddPhoto,
      emptyFileInput,
      fileInput,
      internal,
      isPhotoLoading,
      isPhotoEdit,
      editPhotoItem,
      activeImageIndex,
      locationRequired,

      breastSizes: Object.values(BreastSize),
      hairColorMap,
      hipMap,
      fieldMap,
      bodyMap,
      purposeMap,
      interestsMap,

      nameRef,
      aboutRef,
      cityRef,
      heightInputRef,
      weightInputRef,
      birthdateRef,
      hairTagsRef,
      breastTagsRef,
      hipTagsRef,
      bodyTagsRef,
      purposeTagsRef,
      birthdate,
      yearsOld,
      shouldValidate,
      rules,
      minLength: minLength(5),
      cities,

      isFetching,
      handleSubmit,

      TextfieldStyle,
      TxtWeight,
      Size,
      Sex,
      IconType,
      IconName,
      MainColor,
      ContactType,
      BtnType,
    };
  },
});

export default EditForm;
