import { computed, defineComponent } from 'vue';
import { IconName, IconType } from 'components/Icon';
import { TxtType, TxtWeight } from 'components/Txt';
import { MainColor } from 'core/styles';
import { useCurrency } from 'composables/currency';

const SettingsBar = defineComponent({
  props: {
    balance: {
      type: Number,
      required: true,
    },
  },
  emits: ['edit', 'settings', 'wallet'],
  setup(props, { emit }) {
    const { formatCurrency } = useCurrency();
    const formatBalance = computed(() => formatCurrency(props.balance));

    function edit() {
      emit('edit');
    }
    function settings() {
      emit('settings');
    }
    function wallet() {
      emit('wallet');
    }
    return {
      formatBalance,
      edit,
      settings,
      wallet,

      IconName,
      IconType,
      TxtWeight,
      TxtType,
      MainColor,
    };
  },
});

export default SettingsBar;
