import { render } from "./WalletTransactions.vue?vue&type=template&id=6fb181ee"
import script from "./WalletTransactions.ts?vue&type=script&lang=ts"
export * from "./WalletTransactions.ts?vue&type=script&lang=ts"

import "./walletTransactions.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6fb181ee"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6fb181ee', script)) {
    api.reload('6fb181ee', script)
  }
  
  module.hot.accept("./WalletTransactions.vue?vue&type=template&id=6fb181ee", () => {
    api.rerender('6fb181ee', render)
  })

}

script.__file = "src/views/Profile/WalletTransactions.vue"

export default script