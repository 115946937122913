import { ref } from 'vue';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { IProfile, Sex } from 'api/users/models/user';
import { useRouter } from 'vue-router';

function checkFemale(profile: IProfile) {
  return !(profile.name
  && profile.birth
  && profile.city
  && profile.height
  && profile.weight
  && profile.properties.hair
  && profile.properties.breast
  && profile.properties.hips
  && profile.properties.purpose);
}

function checkMale(profile: IProfile) {
  return !(profile.name
  && profile.birth
  && profile.city
  && profile.height
  && profile.weight
  && profile.properties.hair
  && profile.properties.body
  && profile.properties.purpose);
}

export function useFillOutProfile() {
  const store = useStore();
  const router = useRouter();

  const isShow = ref(false);

  function checkProfile() {
    const profile = store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`] as IProfile;
    let isFillOutNeeded = false;
    if (profile.gender === Sex.Male) isFillOutNeeded = checkMale(profile);
    else isFillOutNeeded = checkFemale(profile);
    isShow.value = isFillOutNeeded;

    return !isFillOutNeeded;
  }

  function close() {
    router.back();
  }

  function fillOut() {
    isShow.value = false;
    router.push({ name: 'profile', hash: '#edit' });
  }

  return {
    isShow,
    close,
    fillOut,
    checkProfile,
  };
}
