import { render } from "./UserList.vue?vue&type=template&id=1accf4e2"
import script from "./UserList.ts?vue&type=script&lang=ts"
export * from "./UserList.ts?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "1accf4e2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1accf4e2', script)) {
    api.reload('1accf4e2', script)
  }
  
  module.hot.accept("./UserList.vue?vue&type=template&id=1accf4e2", () => {
    api.rerender('1accf4e2', render)
  })

}

script.__file = "src/views/Catalog/UserList/UserList.vue"

export default script