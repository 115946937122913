<template>
  <auth-layout
    show-back-btn
    :title="title"
  >
    <form
      v-if="!success"
      novalidate="true"
      class="auth-layout-form mb-4"
      @submit.prevent="onSubmit"
    >
      <textfield
        ref="emailRef"
        v-model="model.email"
        :floating-label="$t('pages.profile.emailChange.placeholder')"
        :rules="[rules.required, rules.email]"
        :should-validate="shouldValidate"
        type="email"
      />
      <textfield
        ref="passwordRef"
        v-model="model.password"
        :type="showPassword ? 'text' : 'password'"
        :floating-label="$t('pages.profile.password')"
        :rules="[rules.required, rules.password]"
        :should-validate="shouldValidate"
      >
        <template #right>
          <icon-btn
            :size="Size.M"
            class="auth-layout-toggle-button"
            @click="showPassword = !showPassword;"
          >
            <icon
              :type="IconType.Contour"
              :name="showPassword ? IconName.EyeClosed : IconName.Eye"
              :size="Size.M"
            />
          </icon-btn>
        </template>
      </textfield>
      <Btn
        action-type="submit"
        w="100%"
        class="auth-layout-form__submit mt-2"
        :disabled="isFetching"
      >
        {{ $t('pages.profile.save') }}
      </Btn>
    </form>

    <div
      v-else
      class="mb-4"
    >
      <div class="py-8" />
      <img src="@/assets/images/svg/email.svg">
      <div class="text-left text-dark-grey mt-8">
        {{ $t('pages.profile.emailChange.success.message') }}
      </div>
      <Btn
        w="100%"
        class="auth-layout-form__submit mt-6"
        :to="{ name: RouteNames.ProfileSettings }"
      >
        {{ $t('pages.profile.emailChange.success.submit') }}
      </Btn>
    </div>
  </auth-layout>
</template>

<script lang="ts" src="./EmailChange.ts"></script>
