<template>
  <empty
    v-if="users.length === 0"
    style="padding-top: 0"
    @action-click="resetFilter"
  />
  <list
    v-else
    :has-next="hasNext"
    :is-fetching="isFetching"
    :users="users"
    @load="onLoad"
  />
</template>

<script lang="ts" src="./UserList.ts" />
