import { computed, defineComponent, PropType, ref } from 'vue';
import { Size } from 'core/styles';

const TagList = defineComponent({
  props: {
    modelValue: {
      type: [Array, String],
      default: () => [],
    },
    map: {
      type: [Object, Array],
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<Size>,
      default: Size.M,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const model = computed<any[]>({
      get() {
        return Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue];
      },
      set(value) {
        emit('update:modelValue', props.multiple ? value : value[0]);
      },
    });

    const tagListRef = ref();
    const hasError = ref(false);

    function isSelected(key: any): boolean {
      return model.value.includes(key);
    }

    function handleClick(key: any) {
      if (isSelected(key)) {
        model.value = model.value.filter((value) => value !== key);
      } else if (props.multiple) {
        model.value = model.value.concat([key]);
      } else {
        model.value = [key];
      }
    }

    const isArray = computed(() => Array.isArray(props.map));

    function validate() {
      if (!props.required) return true;

      hasError.value = !model.value.length;
      return !hasError.value;
    }
    function focus() {
      if (!tagListRef.value) return;

      tagListRef.value.scrollIntoView({ block: 'center' });
    }

    return {
      tagListRef,
      model,
      hasError,
      isArray,
      isSelected,
      handleClick,

      validate,
      focus,
    };
  },
});

export default TagList;
