<template>
  <main class="user-layout">
    <icon-btn
      class="user-layout__back"
      :size="Size.M"
      :color="MainColor.White"
      @click="$router.back()"
    >
      <icon
        :type="IconType.Contour"
        :name="IconName.ArrowBack"
        :size="Size.S"
      />
    </icon-btn>

    <section class="user-layout-photo-viewer">
      <div class="user-layout-photo-viewer__empty user-layout-photo-viewer__empty_profile">
        <icon
          :type="IconType.Big"
          :name="IconName.Ghost"
          :size="Size.Xxl"
          :color="MainColor.Grey4"
          class="user-layout-photo-viewer__empty__icon"
        />
        <div class="user-layout-photo-viewer__empty__title">
          Фото недоступно
        </div>
      </div>
    </section>

    <section class="user-layout-info">
      <div class="user-layout-info__title">
        <txt
          :type="TxtType.Heading3"
          :weight="TxtWeight.SemiBold"
          style="color:#939BAB"
        >
          {{ title }}
        </txt>
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import { IconName, IconType } from 'components/Icon';
import { TxtType, TxtWeight } from 'components/Txt';
import { MainColor, Size } from 'core/styles';
import { defineComponent } from 'vue';

const InactiveUser = defineComponent({
    props: {
        title: {
            type: String,
            required: true,
        },
    },
    setup() {
        return {
            IconType,
            IconName,
            Size,
            MainColor,
            TxtType,
            TxtWeight,
        };
    },
});
export default InactiveUser;
</script>
