import { defineComponent, onBeforeMount, onBeforeUnmount, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { IconType, IconName } from 'components/Icon';
import { Size } from 'core/styles';
import Modal, { ModalHeader, ModalHeaderDirection } from 'components/Modal';
import Prompt from 'components/Prompt';
import { IProfile } from 'api/users/models/user';
import { UsersGetter, useUsersGetter } from 'store/users';
import { useCurrency } from 'composables/currency';
import { PaymentType } from 'core/types';
import { RouteNames } from 'router/names';
import PackageOfServices from 'components/PackageOfServices';
import ActiveServices from './components/ActiveServices';
import PackageDiscount from './components/PackageDiscount';

const Wallet = defineComponent({
  name: RouteNames.ProfileWallet,
  components: {
    Modal,
    ModalHeader,
    Prompt,
    PackageOfServices,
    ActiveServices,
    PackageDiscount,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const { formatCurrency } = useCurrency();

    const paymentMethodSelect = ref(false);

    const paymentMethodItems = computed(() => [
      {
        type: PaymentType.Card,
        text: t('pages.profile.wallet.card'),
        action: () => {
          router.push({
            name: 'profile-wallet-refill',
            params: { type: PaymentType.Card },
          });
        },
      },
      process.env.VUE_APP_PAYMENT_LOCAL_ENABLED === 'true' && {
        type: PaymentType.Local,
        text: t('pages.profile.wallet.local'),
        action: () => {
          router.push({ name: 'profile-wallet-refill' });
        },
      },
    ].filter(Boolean) as Array<{ type: string; text: string; action: () => void }>);

    const profile = computed(() => useUsersGetter<IProfile>(UsersGetter.Profile));
    const balance = computed(() => profile.value.balance);

    onBeforeMount(async () => {
      document.body.classList.add('auth-layout--body');
    });
    onBeforeUnmount(() => {
      document.body.classList.remove('auth-layout--body');
    });

    function back() {
      router.back();
    }

    function openPaymentMethodSelect() {
      if (paymentMethodItems.value.length === 1 &&
          paymentMethodItems.value[0].type === PaymentType.Local) {
        paymentMethodItems.value[0].action();
      } else {
        paymentMethodSelect.value = true;
      }
    }

    return {
      t,

      balance,

      paymentMethodSelect,
      paymentMethodItems,
      openPaymentMethodSelect,

      formatCurrency,
      back,

      IconType,
      IconName,
      Size,
      ModalHeaderDirection,
      RouteNames,
    };
  },
});

export default Wallet;
