import { User } from 'api/users/models/user';
import MainLayout from 'layouts/MainLayout';
import UserLayout from 'layouts/UserLayout';
import { UsersGetter, useUsersGetter } from 'store/users';
import { computed, defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { toastEmitter, ToastType } from 'components/Toasts';
import { useRoute, useRouter } from 'vue-router';
import { RouteNames } from 'router/names';
import AboutTelegram from 'layouts/ListLayout/components/AboutTelegram';
import EditForm from './components/EditForm';

const Profile = defineComponent({
  name: RouteNames.Profile,
  components: {
    MainLayout,
    UserLayout,
    EditForm,
    AboutTelegram,
  },

  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const uploadedPhotos = ref<File[]>([]);

    const user = computed(() => useUsersGetter<User>(UsersGetter.Profile));
    const isHeightOpen = computed(() => route.hash === '#height');
    const isModalOpen = computed(() => route.hash === '#edit');

    const isEdit = ref(isHeightOpen.value || isModalOpen.value);

    function showAlert() {
      toastEmitter.emit('toast', { type: ToastType.Success, message: t('pages.profile.saved') });
    }

    function onUploaded(files: File[]) {
      uploadedPhotos.value = files;
      isEdit.value = true;
    }

    function onClose() {
      isEdit.value = false;
      uploadedPhotos.value = [];
    }

    const isTelegramPopupShow = ref(false);
    const closeTelegramPopup = () => {
      isTelegramPopupShow.value = false;
    };

    watch(isEdit, (v, oldV) => {
      if (oldV === true && v === false && route.path === '/success' && route.hash === '#edit') {
        isTelegramPopupShow.value = true;
      }

      if (!v) {
        router.push(route.path);
      }
    });

    return {
      isEdit,
      uploadedPhotos,
      isHeightOpen,
      isTelegramPopupShow,
      closeTelegramPopup,

      user,

      showAlert,
      onUploaded,
      onClose,
    };
  },
});

export default Profile;
