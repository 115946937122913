import { render } from "./WalletRefill.vue?vue&type=template&id=5fe1f1e2"
import script from "./WalletRefill.ts?vue&type=script&lang=ts"
export * from "./WalletRefill.ts?vue&type=script&lang=ts"

import "./wallet-refill.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5fe1f1e2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5fe1f1e2', script)) {
    api.reload('5fe1f1e2', script)
  }
  
  module.hot.accept("./WalletRefill.vue?vue&type=template&id=5fe1f1e2", () => {
    api.rerender('5fe1f1e2', render)
  })

}

script.__file = "src/views/Profile/WalletRefill.vue"

export default script