import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import {
  UsersGetter, useUsersGetter, UsersMutation, useUsersMutation, useUsersWatch,
} from 'store/users';
import { Size } from 'core/styles';
import { TxtType, TxtWeight } from 'components/Txt';
import { BtnType } from 'components/Btn';
import { useFillOutProfile } from 'composables/fillOutProifle';
import { RouteNames, SubscriptionRoutesName } from 'router/names';
import CreateSubscription from 'components/CreateSubscription';
import { IUserFilter } from 'api/users/models/user/interfaces/IUserFilter';
import { ModalHeader } from 'components/Modal';
import FilterLayout from 'layouts/FilterLayout';
import { useStore } from 'vuex';
import AboutSubscription from 'views/Subscriptions/components/About';
import { StorageKeys, storageService } from 'storage';
import FilterForm from '../components/FilterForm';
import FillOutProfile from '../components/FillOutProfile';

const Filter = defineComponent({
  name: RouteNames.CatalogFilter,
  components: {
    FilterForm,
    CreateSubscription,
    ModalHeader,
    FilterLayout,
    FillOutProfile,
    AboutSubscription,
  },

  setup() {
    // @ts-ignore
    const store = useStore();
    const router = useRouter();
    const { isShow: isFillOutProfileShow, checkProfile } = useFillOutProfile();
    const isProfileFillOut = ref(checkProfile());
    const isCreateSubscriptionShow = ref(false);

    const initFilter = useUsersGetter<IUserFilter>(UsersGetter.Filter);
    const defaultFilter = useUsersGetter<IUserFilter>(UsersGetter.FilterDefaults);
    const filter = ref({ ...defaultFilter, ...initFilter });

    const setFilterMutation = useUsersMutation<IUserFilter>(UsersMutation.SetFilter);
    const clearFilterMutation = useUsersMutation(UsersMutation.ClearFilter);

    useUsersWatch(UsersGetter.Filter, (val) => {
      filter.value = { ...defaultFilter, ...val };
    });

    function onModalClose() {
      router.back();
    }

    function onFilterClean() {
      clearFilterMutation(null);
      filter.value = { ...defaultFilter };

      goToCatalog();
    }

    function onFilterSubmit(data: Record<string, unknown>) {
      setFilterMutation(data);

      goToCatalog();
      filter.value = { ...defaultFilter };
    }

    async function goToCatalog() {
      router.push({ name: 'catalog' });
    }

    function reduceHairs(hairs: string[]) {
      return hairs.reduce((acc, value) => `${acc}${acc ? ', ' : ''} ${value.toLowerCase()}`, '');
    }

    function createSubscription() {
      isCreateSubscriptionShow.value = true;
    }

    function successCreation() {
      filter.value = { ...defaultFilter };
      router.push({ name: SubscriptionRoutesName.Subscriptions });
    }

    const isAboutSubscriptionPopupShow = ref(false);
    const handleAboutSubscriptionSubmit = () => {
      isAboutSubscriptionPopupShow.value = false;
      createSubscription();
    };

    function handleSubscribeClick() {
      if (storageService.getItem(StorageKeys.AboutSubscriptionViewed) === '1') createSubscription();
      else isAboutSubscriptionPopupShow.value = true;
    }

    return {
      isProfileFillOut,
      filter,
      isFillOutProfileShow,
      createSubscription,
      isCreateSubscriptionShow,
      reduceHairs,
      successCreation,
      handleSubscribeClick,

      isAboutSubscriptionPopupShow,
      handleAboutSubscriptionSubmit,

      onModalClose,
      onFilterClean,
      onFilterSubmit,

      Size,
      TxtType,
      TxtWeight,
      BtnType,
      SubscriptionRoutesName,
    };
  },
});

export default Filter;
