<template>
  <div
    v-if="favorites.length === 0"
    class="favorites-note"
  >
    <icon
      :name="IconName.Favorites"
      :type="IconType.Big"
    />
    <span class="favorites-note__text">{{ $t('pages.favorites.note') }}</span>
    <btn
      w="100%"
      class="favorites-note__btn"
      @click="goToCatalog"
    >
      {{ $t('pages.favorites.btnViewCatalog') }}
    </btn>
  </div>
  <list
    v-else
    :has-next="hasNext"
    :is-fetching="isFetching"
    :users="favorites"
    @load="onLoad"
  />
</template>

<script lang="ts" src="./Favorites.ts" />
<style lang="scss" src="./favorites.scss" />
