<template>
  <main class="wallet">
    <ModalHeader
      :title="t('pages.profile.wallet.title')"
      :dir="ModalHeaderDirection.Ltr"
      relative
      @close="back"
    >
      <template #close-icon>
        <icon
          :type="IconType.Contour"
          :name="IconName.ArrowBack"
          :size="Size.S"
        />
      </template>
    </ModalHeader>

    <div class="wallet-content">
      <div class="wallet-card">
        <div class="wallet-card__balance-wrapper">
          <h3>{{ t('pages.profile.wallet.balance') }}</h3>
          <btn
            class="wallet-card__show-transactions"
            :to="{ name: RouteNames.ProfileWalletTransactions }"
          >
            <icon
              :name="IconName.CountdownTimer"
              :type="IconType.Contour"
            />
          </btn>
        </div>
        <div class="wallet-card__balance">{{ formatCurrency(balance) }}</div>
        <div class="wallet-card__action">
          <btn
            w="100%"
            @click="openPaymentMethodSelect"
          >
            {{ t('pages.profile.wallet.topUp') }}
          </btn>
        </div>
      </div>

      <package-discount />
      <active-services />
      <package-of-services />
    </div>
  </main>

  <Prompt
    v-model="paymentMethodSelect"
    hide-cancel
    ok-text-path="pages.profile.wallet.cancel"
    class="payment-method-select"
  >
    <div class="payment-method-select__content">
      <div
        v-for="item in paymentMethodItems"
        :key="item.type"
        class="prompt__action"
        @click="item.action"
      >
        {{ item.text }}
      </div>
    </div>
  </Prompt>
</template>

<script lang="ts" src="./Wallet.ts"></script>
<style lang="scss" src="./wallet.scss"></style>
