<template>
  <div class="user-to-telegram">
    <a
      :href="TelegramUrl"
      target="_blank"
      class="user-to-telegram__link"
    >
      <icon
        :name="IconName.Telegram"
        :type="IconType.Contour"
        :size="Size.S"
        class="mr-1"
      />
      {{ $t('layouts.userLayout.telegramLink') }}</a>
    <span class="user-to-telegram__text">
      {{ $t('layouts.userLayout.tgToken') }}
    </span>
    <copy-text class="user-to-telegram__copy">{{ tgToken }}</copy-text>
  </div>
</template>

<script lang="ts" src="./ToTelegram.ts" />
<style lang="scss" src="./toTelegram.scss" />
