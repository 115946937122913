<template>
  <about-subscription
    v-if="isAboutSubscriptionPopupShow"
    @submit="handleAboutSubscriptionSubmit"
  />
  <create-subscription
    v-model="isCreateSubscriptionShow"
    :filter="filter"
    @success="successCreation"
  />
  <filter-layout
    v-if="!isFillOutProfileShow"
    :title="$t('pages.catalog.filter.title')"
    :action-text="$t('pages.clean')"
    @close="onModalClose"
    @action="onFilterClean"
  >
    <filter-form
      v-if="isProfileFillOut"
      v-model="filter"
      @submit="onFilterSubmit"
    >
      <template #footer="{ filters, hairs }">
        <txt
          :type="TxtType.SmallText"
          class="block text-center"
        >
          {{ $t('pages.form.or') }}
        </txt>
        <btn
          outlined
          w="100%"
          class="my-4"
          @click="handleSubscribeClick"
        >
          {{ $t('pages.form.typeSubsAction') }}
        </btn>
        <txt
          :weight="TxtWeight.Medium"
          :leading="Size.L"
          class="block pb-8"
        >
          {{ $t(`pages.form.typeSubsDesc.${filters.gender}`, { txt: reduceHairs(hairs) || $t(`pages.form.gender.${filters.gender}`) }) }}
        </txt>
      </template>
    </filter-form>
  </filter-layout>
  <fill-out-profile v-else />
</template>

<script lang="ts" src="./Filter.ts" />
