import { defineComponent, reactive, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import AuthLayout from 'layouts/AuthLayout';
import Stepper from 'components/Stepper';
import CodeInput from 'components/CodeInput';
import { BtnType } from 'components/Btn';
import { useApiErrors } from 'composables/apiErrors';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersAction } from 'store/users';
import { Size } from 'core/styles';
import { IconName, IconType } from 'components/Icon';
import { useRules } from 'composables/rules';
import { RouteNames } from 'router/names';
import { useFetching } from 'composables/fetching';

const EmailChange = defineComponent({
  name: RouteNames.ProfileEmailChange,
  components: {
    AuthLayout,
    Stepper,
    CodeInput,
  },

  setup() {
    const { t } = useI18n();
    const { showError } = useApiErrors();
    const store = useStore();
    const showPassword = ref(false);

    const success = ref(false);

    const model = reactive<{
      email: string
      password: string
    }>({
      email: '',
      password: '',
    });
    const shouldValidate = ref(false);
    const emailRef = ref();
    const passwordRef = ref();

    const title = computed(() => (success.value ? '' : t('pages.profile.emailChange.title')));

    function validateEmail() {
      if (!emailRef.value) return false;
      return emailRef.value.validate();
    }

    function validatePassword() {
      if (!passwordRef.value) return false;
      return passwordRef.value.validate();
    }

    function validate() {
      shouldValidate.value = true;

      return validateEmail() && validatePassword();
    }

    const { isFetching, fetch } = useFetching();

    async function onSubmit() {
      if (!isFetching.value && validate()) {
        try {
          await fetch(store.dispatch(`${USER_STORE_KEY}/${UsersAction.ChangeEmail}`, {
            email: model.email,
            password: model.password,
          }));
          success.value = true;
        } catch (error) {
          showError(error);
        }
      }
    }

    return {
      ...useRules(),
      success,
      title,
      shouldValidate,
      emailRef,
      passwordRef,
      model,
      showPassword,
      onSubmit,
      isFetching,

      BtnType,
      Size,
      IconName,
      IconType,
      RouteNames,
    };
  },
});

export default EmailChange;
