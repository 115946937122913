import { render } from "./Styling.vue?vue&type=template&id=0d017c5e"
import script from "./Styling.ts?vue&type=script&lang=ts"
export * from "./Styling.ts?vue&type=script&lang=ts"

import "./styling.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "0d017c5e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0d017c5e', script)) {
    api.reload('0d017c5e', script)
  }
  
  module.hot.accept("./Styling.vue?vue&type=template&id=0d017c5e", () => {
    api.rerender('0d017c5e', render)
  })

}

script.__file = "src/views/Profile/Styling.vue"

export default script