import { render } from "./DeletionReasonPopup.vue?vue&type=template&id=483b3b4c"
import script from "./DeletionReasonPopup.ts?vue&type=script&lang=ts"
export * from "./DeletionReasonPopup.ts?vue&type=script&lang=ts"

import "./deletionReasonPopup.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "483b3b4c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('483b3b4c', script)) {
    api.reload('483b3b4c', script)
  }
  
  module.hot.accept("./DeletionReasonPopup.vue?vue&type=template&id=483b3b4c", () => {
    api.rerender('483b3b4c', render)
  })

}

script.__file = "src/views/Profile/components/DeletionReasonPopup/DeletionReasonPopup.vue"

export default script