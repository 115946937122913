<template>
  <div class="profile-settings">
    <ModalHeader
      :title="$t('pages.profile.settingsTitle')"
      :dir="ModalHeaderDirection.Ltr"
      relative
      @close="goToProfile"
    />

    <div
      v-for="item in items"
      class="profile-settings-item"
      @click="item.onClick"
    >
      <div class="profile-settings-item__icon">
        <icon
          :type="item.iconType"
          :name="item.iconName"
          :size="Size.S"
        />
      </div>
      <txt
        tag="div"
        :weight="TxtWeight.Medium"
      >
        {{ item.label }}
      </txt>
    </div>

    <prompt
      v-model="deleteProfileConfirmationPrompt"
      :title="$t('pages.profile.deleteProfileConfirm.title')"
      ok-text-path="pages.profile.deleteProfileConfirm.ok"
      @ok="deleteProfilePrompt = true"
    >
      {{ $t('pages.profile.deleteProfileConfirm.content') }}
    </prompt>

    <prompt
      v-model="deleteProfilePrompt"
      :title="$t('pages.profile.deleteProfileTimer.title')"
      cancel-text-path="pages.profile.deleteProfileTimer.cancel"
      hide-ok
    >
      {{ $t('pages.profile.deleteProfileTimer.content') }} {{ timer }}...
    </prompt>

    <deletion-reason-popup
      v-model="isDeletionReasonPopupShow"
      @submit="handleDeletionReasonSubmit"
    />
  </div>
</template>

<script lang="ts" src="./Settings.ts"></script>
<style lang="scss" src="./settings.scss"></style>
