<template>
  <div
    v-if="activeServices.length"
    class="active-services"
  >
    <txt :type="TxtType.Heading2">
      {{ $t('components.activeServices.title') }}
    </txt>
    <div
      class="active-services__wrapper"
      :class="`active-services__wrapper--${activeThemeColor}`"
    >
      <txt
        v-for="text in activeServices"
        :key="text"
        :weight="TxtWeight.Bold"
      >
        {{ text }}
      </txt>
    </div>
  </div>
</template>

<script lang="ts" src="./ActiveServices.ts" />
<style lang="scss" src="./activeServices.scss" />
