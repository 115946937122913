<template>
  <auth-layout
    show-back-btn
    :title="!success && $t('pages.profile.passwordChange.title')"
  >
    <div
      v-if="success"
      class="mb-4"
    >
      <div class="py-8 mb-3" />
      <img src="@/assets/images/svg/pwd.svg">
      <div class="text-left text-dark-grey mt-8">
        {{ $t('pages.profile.passwordChange.success') }}
      </div>
      <Btn
        w="100%"
        class="auth-layout-form__submit mt-6"
        @click="$router.push({ name: 'profile-settings' })"
      >
        {{ $t('pages.profile.passwordChange.back') }}
      </Btn>
    </div>

    <template v-else>
      <form
        novalidate="true"
        class="auth-layout-form mb-4"
        @submit.prevent="onSubmit"
      >
        <textfield
          ref="currentPasswordRef"
          v-model="model.currentPassword"
          :type="showCurrentPassword ? 'text' : 'password'"
          :floating-label="$t('pages.profile.passwordChange.currentPassword')"
          :rules="[rules.required, rules.password]"
          :should-validate="shouldValidate"
        >
          <template #right>
            <icon-btn
              :size="Size.M"
              class="auth-layout-toggle-button"
              @click="showCurrentPassword = !showCurrentPassword;"
            >
              <icon
                :type="IconType.Contour"
                :name="showCurrentPassword ? IconName.EyeClosed : IconName.Eye"
                :size="Size.M"
              />
            </icon-btn>
          </template>
        </textfield>
        <textfield
          ref="passwordRef"
          v-model="model.password"
          :type="showPassword ? 'text' : 'password'"
          :floating-label="$t('pages.profile.passwordChange.newPassword')"
          :rules="[rules.required, rules.password]"
          :should-validate="shouldValidate"
        >
          <template #right>
            <icon-btn
              :size="Size.M"
              class="auth-layout-toggle-button"
              @click="showPassword = !showPassword;"
            >
              <icon
                :type="IconType.Contour"
                :name="showPassword ? IconName.EyeClosed : IconName.Eye"
                :size="Size.M"
              />
            </icon-btn>
          </template>
        </textfield>
        <textfield
          ref="passwordConfirmRef"
          v-model="model.passwordConfirm"
          :type="showConfirmPassword ? 'text' : 'password'"
          :floating-label="$t('pages.profile.passwordChange.repeatPassword')"
          :rules="[rules.required, rules.password, confirmPassword]"
          :should-validate="shouldValidate"
        >
          <template #right>
            <icon-btn
              :size="Size.M"
              class="auth-layout-toggle-button"
              @click="showConfirmPassword = !showConfirmPassword;"
            >
              <icon
                :type="IconType.Contour"
                :name="showConfirmPassword ? IconName.EyeClosed : IconName.Eye"
                :size="Size.M"
              />
            </icon-btn>
          </template>
        </textfield>
        <Btn
          action-type="submit"
          w="100%"
          class="auth-layout-form__submit mt-2 mb-0"
          :disabled="isFetching"
        >
          {{ $t('pages.profile.passwordChange.changePassword') }}
        </Btn>
      </form>
    </template>
  </auth-layout>
</template>

<script lang="ts" src="./PasswordChange.ts"></script>
