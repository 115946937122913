<template>
  <div
    v-show="internal.photos.length === 0 && !isPhotoLoading"
    class="edit-form-photo__empty"
  >
    <icon
      :type="IconType.Contour"
      :name="IconName.Camera"
      :size="Size.Xl"
      :color="MainColor.Grey4"
    />
    <div class="edit-form-photo__empty__title">
      {{ $t('pages.profile.addPhotoTitle') }}
    </div>
    <div class="edit-form-photo__empty__subtitle">
      {{ $t('pages.profile.addPhotoSubtitle') }}
    </div>
    <btn
      :type="BtnType.Secondary"
      class="edit-form-photo__empty__upload"
    >
      {{ $t('pages.profile.addPhotoUpload') }}
      <input
        type="file"
        accept="image/*"
        @change="handleAddPhoto"
      >
    </btn>
  </div>
  <div
    v-if="isPhotoLoading"
    class="edit-form-photo__loading"
  >
    <icon
      class="edit-form-photo__loading_icon"
      :type="IconType.Big"
      :name="IconName.Image"
      :size="Size.Xl"
    />
  </div>
  <section class="edit-form">
    <add-photos
      v-if="internal.photos.length > 0"
      class="edit-form__add-photo pb-8"
      :photos="internal.photos"
    />

    <div
      v-if="internal.photos.length > 0"
      class="edit-form__row edit-form__row_tags__typical"
    >
      <label>
        {{ $t('pages.profile.form.typicalPhoto') }}
      </label>

      <toggler
        v-model="internal.typical_photo"
      />
    </div>

    <div class="edit-form__row">
      <textfield
        ref="nameRef"
        v-model="internal.name"
        :style="TextfieldStyle.Form"
        :label="$t('pages.profile.form.name')"
        :should-validate="shouldValidate"
        :rules="[rules.required]"
        class="edit-form__textfield"
      />
    </div>

    <div class="edit-form__row">
      <textfield
        ref="birthdateRef"
        v-model="birthdate"
        :style="TextfieldStyle.Form"
        :label="$t('pages.profile.form.birthdate')"
        :placeholder="$t('pages.profile.form.birthdatePlaceholder')"
        :should-validate="shouldValidate"
        :rules="[rules.required, rules.legal]"
        :rt="yearsOld"
        mask="##.##.####"
        type="text"
        inputmode="tel"
        class="edit-form__textfield edit-form__textfield-birthdate"
      />
    </div>

    <div class="edit-form__row">
      <autocomplete
        ref="cityRef"
        v-model="internal.city"
        :label="$t('pages.profile.form.city')"
        :rules="[rules.select]"
        :should-validate="shouldValidate"
        :items="cities"
        regular
        no-filter
        item-value="id"
        item-text="name"
        teleport="#modals .modal-body"
        scroll-body="#modals .modal-body"
        select
      >
        <template #item="{ item }">
          <div class="edit-form__city-item">{{ item.name }}</div>
        </template>
      </autocomplete>
    </div>

    <div class="edit-form__row">
      <textfield
        ref="heightInputRef"
        v-model="internal.height"
        :style="TextfieldStyle.Form"
        :should-validate="shouldValidate"
        :rules="[rules.required, rules.height]"
        :label="$t('pages.profile.form.height')"
        class="edit-form__textfield"
        inputmode="numeric"
      />
    </div>

    <div class="edit-form__row">
      <textfield
        ref="weightInputRef"
        v-model="internal.weight"
        :style="TextfieldStyle.Form"
        :should-validate="shouldValidate"
        :rules="[rules.required]"
        :label="$t('pages.profile.form.weight')"
        class="edit-form__textfield"
        inputmode="numeric"
      />
    </div>

    <div class="edit-form__row edit-form__row_tags__geo">
      <label>
        {{ $t('pages.profile.form.geolocationRequired') }}
      </label>

      <toggler
        v-model="locationRequired"
      />
    </div>

    <tag-list
      ref="hairTagsRef"
      v-model="internal.properties.hair"
      :map="hairColorMap"
      :size="Size.M"
      class="edit-form__row edit-form__row_tags"
      :title="$t('pages.profile.form.hair')"
    >
      <template #marker="{ value }">
        <div
          :class="{
            'edit-form__color': true,
            'edit-form__color_transparent': value === 'transparent',
          }"
          :style="`background: ${value}`"
        />
      </template>

      <template #default="{ key }">
        <txt
          :weight="TxtWeight.Medium"
          :size="Size.L"
        >
          {{ $tc(`user.hairColor.${key}.${internal.gender}`) }}
        </txt>
      </template>
    </tag-list>

    <tag-list
      v-if="internal.gender === Sex.Female"
      ref="breastTagsRef"
      v-model="internal.properties.breast"
      :map="breastSizes"
      :size="Size.L"
      class="edit-form__row edit-form__row_tags"
      circle
      :title="$t('pages.profile.form.breast')"
    >
      <template #default="{ value }">
        <txt
          :weight="TxtWeight.Medium"
          :size="Size.L"
        >
          {{ $t(`user.breast.${value}`) }}
        </txt>
      </template>
    </tag-list>

    <tag-list
      v-if="internal.gender === Sex.Female"
      ref="hipTagsRef"
      v-model="internal.properties.hips"
      :map="hipMap"
      :size="Size.M"
      class="edit-form__row edit-form__row_tags"
      :title="$t('pages.profile.form.hip')"
    >
      <template #marker="{ value }">
        <icon
          :type="IconType.Fill"
          :name="value"
          :size="Size.M"
        />
      </template>
      <template #default="{ key }">
        <txt
          :weight="TxtWeight.Medium"
          :size="Size.L"
        >
          {{ $t(`user.hip.${key}`) }}
        </txt>
      </template>
    </tag-list>

    <tag-list
      v-if="internal.gender === Sex.Male"
      v-model="internal.properties.activity"
      :map="fieldMap"
      :size="Size.M"
      multiple
      class="edit-form__row edit-form__row_tags"
      :title="$t('pages.profile.form.field')"
    >
      <template #marker="{ value }">
        {{ value }}
      </template>
      <template #default="{ key }">
        <txt
          :weight="TxtWeight.Medium"
          :size="Size.L"
        >
          {{ $t(`user.field.${key}`) }}
        </txt>
      </template>
    </tag-list>

    <tag-list
      v-if="internal.gender === Sex.Male"
      ref="bodyTagsRef"
      v-model="internal.properties.body"
      :map="bodyMap"
      :size="Size.M"
      class="edit-form__row edit-form__row_tags"
      :title="$t('pages.profile.form.body')"
    >
      <template #marker="{ value }">
        <icon
          :type="IconType.Fill"
          :name="value"
          :size="Size.M"
        />
      </template>
      <template #default="{ key }">
        <txt
          :weight="TxtWeight.Medium"
          :size="Size.L"
        >
          {{ $t(`user.body.${key}`) }}
        </txt>
      </template>
    </tag-list>

    <tag-list
      ref="purposeTagsRef"
      v-model="internal.properties.purpose"
      :map="purposeMap"
      class="edit-form__row edit-form__row_tags"
      :title="$t('pages.profile.form.purpose')"
      multiple
    >
      <template #marker="{ value }">
        {{ value }}
      </template>
      <template #default="{ key }">
        <txt
          :weight="TxtWeight.Medium"
          :size="Size.L"
        >
          {{ $t(`user.purpose.${key}`) }}
        </txt>
      </template>
    </tag-list>

    <tag-list
      v-model="internal.properties.interests"
      :map="interestsMap"
      class="edit-form__row edit-form__row_tags"
      :title="$t('pages.profile.form.interests')"
      multiple
    >
      <template #marker="{ value }">
        {{ value }}
      </template>
      <template #default="{ key }">
        <txt
          :weight="TxtWeight.Medium"
          :size="Size.L"
        >
          {{ $t(`user.interest.${key}`) }}
        </txt>
      </template>
    </tag-list>

    <div class="edit-form__row">
      <textfield
        ref="aboutRef"
        v-model="internal.description"
        :style="TextfieldStyle.Form"
        :label="$t('pages.profile.form.about')"
        :rows="5"
        :rules="[rules.required, minLength]"
        textarea
        class="edit-form__textfield"
      />
    </div>

    <div class="edit-form__row edit-form__add-photo">
      <txt
        tag="div"
        class="pb-3"
        :weight="TxtWeight.Medium"
      >
        {{ $t('pages.profile.form.privatePhoto.title') }}
      </txt>
      <add-photos
        class="pb-5"
        :photos="internal.private_photos"
        private
      />
    </div>

    <div class="edit-form__row edit-form__row_contacts">
      <txt
        tag="div"
        :weight="TxtWeight.Medium"
      >
        {{ $t('pages.profile.form.contacts.desc') }}
      </txt>

      <strong class="edit-form__row_contacts-attention">
        {{ $t('pages.profile.form.contacts.attention') }}
      </strong>

      <add-contacts @valid="internal.contacts = $event" />
    </div>
  </section>

  <div class="edit-form-submit">
    <btn
      w="100%"
      :disabled="isFetching"
      @click="handleSubmit"
    >
      {{ $t('pages.profile.save') }}
    </btn>
  </div>
</template>

<script lang="ts" src="./EditForm.ts"></script>
<style lang="scss" src="./edit-form.scss"></style>
