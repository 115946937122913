import { computed, defineComponent } from 'vue';
import { IconName, IconType } from 'components/Icon';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersAction, UsersGetter } from 'store/users';
import { RouteNames } from 'router/names';
import { useFetching } from 'composables/fetching';
import List from '../components/List';

const SuccessUsers = defineComponent({
  name: RouteNames.SuccessCatalog,
  components: {
    List,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    function goToCatalog() {
      router.push({ name: 'catalog' });
    }

    const users = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.SuccessUsers}`]);
    const hasNext = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.SuccessUsersHasNext}`]);

    const { fetch, isFetching } = useFetching();
    function onLoad() {
      fetch(store.dispatch(`${USER_STORE_KEY}/${UsersAction.FetchSuccessUsers}`, true), false);
    }

    return {
      users,
      hasNext,
      isFetching,
      goToCatalog,
      onLoad,

      IconName,
      IconType,
    };
  },
});

export default SuccessUsers;
