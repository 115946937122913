import { computed, defineComponent } from 'vue';
import { PropType } from '@vue/runtime-core';
import { ITransaction } from 'api/users/models/user';
import { useCurrency } from 'composables/currency';
import { useI18n } from 'vue-i18n';
import { MainColor, Size } from 'core/styles';
import { IconName, IconType } from 'components/Icon';
import { TransactionStatus } from 'api/users/models/user/enums/TransactionStatus';
import { TxtType } from 'components/Txt';

const WalletTransaction = defineComponent({
  props: {
    transaction: {
      type: Object as PropType<ITransaction>,
      required: true,
    },
  },
  setup(props) {
    const { d } = useI18n();
    const { formatCurrency } = useCurrency();

    function formatDate(date?: string) {
      if (!date) return '';
      return d(new Date(date), 'long');
    }

    const icon = computed<{ name: IconName, color: MainColor }>(() => {
      switch (props.transaction.status) {
        case TransactionStatus.Failed:
          return {
            name: IconName.Cancel,
            color: MainColor.Red,
          };
        case TransactionStatus.Pending:
          return {
            name: IconName.Clock,
            color: MainColor.Grey4,
          };
        case TransactionStatus.Success:
          return {
            name: IconName.Tick,
            color: MainColor.Green,
          };
        default:
          return {
            name: IconName.Clock,
            color: MainColor.Grey4,
          };
      }
    });

    const isFailed = computed(() => props.transaction.status === TransactionStatus.Failed);
    const isReferShows = computed(() => !props.transaction.refer.match(/lovista/));

    return {
      icon,
      isFailed,
      isReferShows,
      formatCurrency,
      formatDate,

      IconType,
      Size,
      MainColor,
      TxtType,
    };
  },
});

export default WalletTransaction;
