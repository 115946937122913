import { render } from "./SettingsBar.vue?vue&type=template&id=af6a433a"
import script from "./SettingsBar.ts?vue&type=script&lang=ts"
export * from "./SettingsBar.ts?vue&type=script&lang=ts"

import "./settingsBar.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "af6a433a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('af6a433a', script)) {
    api.reload('af6a433a', script)
  }
  
  module.hot.accept("./SettingsBar.vue?vue&type=template&id=af6a433a", () => {
    api.rerender('af6a433a', render)
  })

}

script.__file = "src/layouts/UserLayout/components/SettingsBar/SettingsBar.vue"

export default script