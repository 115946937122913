import { computed, defineComponent, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { PaidPackageResponse } from 'api/users/models/paidPackages/interfaces';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import dayjs from 'dayjs';

const PackageDiscount = defineComponent({
  setup() {
    const store = useStore();
    const discount = computed<Omit<PaidPackageResponse, 'data'>>(
      () => store.getters[`${USER_STORE_KEY}/${UsersGetter.PaidPackageDiscount}`],
    );

    const remainingTime = ref({ mm: 0, hh: 0 });
    const setTime = () => {
      if (!discount.value || !discount.value.discount_for_all_expired_at) return;

      const diff = dayjs(new Date(discount.value.discount_for_all_expired_at)).diff(new Date()) / 1000;
      const hh = Math.floor(diff / 60 / 60);
      const mm = Math.floor((diff - hh * 60 * 60) / 60);

      remainingTime.value = {
        hh,
        mm,
      };
    };
    watchEffect(() => {
      setTime();
    });
    setInterval(setTime, 60 * 1000);

    const withZero = (n: number) => (n < 10 ? `0${n}` : n);

    return {
      discount,
      remainingTime,
      withZero,
    };
  },
});

export default PackageDiscount;
