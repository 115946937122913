<template>
  <about-telegram
    v-if="isTelegramPopupShow"
    @close="closeTelegramPopup"
  />

  <main-layout>
    <user-layout
      v-if="user"
      self
      :user="user"

      @clickEdit="isEdit = true"
      @uploaded="onUploaded"
    />
  </main-layout>

  <modal
    :show="isEdit"
    :header="{
      title: $t('pages.profile.editTitle'),
    }"

    @close="onClose"
  >
    <edit-form
      :uploaded="uploadedPhotos"
      :is-height-focus="isHeightOpen"
      @close="onClose"
      @success="showAlert"
    />
  </modal>
</template>

<script lang="ts" src="./Profile.ts"></script>
