import { render } from "./FillOutProfile.vue?vue&type=template&id=53d5b866"
import script from "./FillOutProfile.ts?vue&type=script&lang=ts"
export * from "./FillOutProfile.ts?vue&type=script&lang=ts"

import "./fillOutProfile.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "53d5b866"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('53d5b866', script)) {
    api.reload('53d5b866', script)
  }
  
  module.hot.accept("./FillOutProfile.vue?vue&type=template&id=53d5b866", () => {
    api.rerender('53d5b866', render)
  })

}

script.__file = "src/views/Catalog/components/FillOutProfile/FillOutProfile.vue"

export default script