<template>
  <div class="user-layout-settings-bar">
    <div
      class="user-layout-settings-bar__item user-layout-settings-bar__item--theme-color"
      @click="wallet"
    >
      <icon
        class="user-layout-settings-bar__icon"
        :name="IconName.Wallet"
        :type="IconType.Fill"
        :color="MainColor.White"
      />
      <div class="user-layout-settings-bar__text">
        <txt
          :weight="TxtWeight.Bold"
          :type="TxtType.Subheading"
        >
          {{ formatBalance }}
        </txt>
        <br>
        <txt
          :weight="TxtWeight.Bold"
          :type="TxtType.SmallText"
        >
          {{ $t('layouts.userLayout.balance') }}
        </txt>
      </div>
    </div>
    <div
      class="user-layout-settings-bar__item"
      @click="settings"
    >
      <icon
        class="user-layout-settings-bar__icon"
        :name="IconName.Settings"
        :type="IconType.Fill"
        :color="MainColor.Grey4"
      />
      <div class="user-layout-settings-bar__text">
        <txt
          :weight="TxtWeight.Bold"
          :type="TxtType.SmallText"
        >
          {{ $t('layouts.userLayout.settings') }}
        </txt>
      </div>
    </div>
    <div
      class="user-layout-settings-bar__item"
      @click="edit"
    >
      <icon
        class="user-layout-settings-bar__icon"
        :name="IconName.Pencil"
        :type="IconType.Fill"
        :color="MainColor.Grey4"
      />
      <div class="user-layout-settings-bar__text">
        <txt
          :weight="TxtWeight.Bold"
          :type="TxtType.SmallText"
        >
          {{ $t('layouts.userLayout.changeInfo') }}
        </txt>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./SettingsBar.ts" />
<style lang="scss" src="./settingsBar.scss" />
