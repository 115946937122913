<template>
  <teleport to="#modals">
    <div class="fill-out-profile">
      <img src="@/assets/images/svg/filters-prompt.svg">

      <div class="fill-out-profile__text">
        <txt
          :type="TxtType.Heading2"
          :weight="TxtWeight.Bold"
        >
          {{ $t('pages.catalog.filter.fillOutProfile.title') }}
        </txt>
        <txt
          :type="TxtType.MainText"
          :weight="TxtWeight.Medium"
        >
          {{ $t('pages.catalog.filter.fillOutProfile.content') }}
        </txt>
      </div>

      <btn
        w="100%"
        class="fill-out-profile__button"
        @click="fillOut"
      >
        {{ $t('pages.catalog.filter.fillOutProfile.fillOut') }}
      </btn>
      <btn
        w="100%"
        class="fill-out-profile__button"
        :type="BtnType.Secondary"
        @click="close"
      >
        {{ $t('pages.catalog.filter.fillOutProfile.notNow') }}
      </btn>
    </div>
  </teleport>
</template>

<script lang="ts" src="./FillOutProfile.ts" />

<style lang="scss" src="./fillOutProfile.scss" />
