import { defineComponent } from 'vue';
import { TxtType, TxtWeight } from 'components/Txt';
import { BtnType } from 'components/Btn';
import { useFillOutProfile } from 'composables/fillOutProifle';

const FillOutProfile = defineComponent({
  setup() {
    return {
      ...useFillOutProfile(),
    };
  },
  data() {
    return {
      TxtWeight,
      TxtType,
      BtnType,
    };
  },
});

export default FillOutProfile;
