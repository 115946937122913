import { render } from "./NotificationSettings.vue?vue&type=template&id=50761ba4"
import script from "./NotificationSettings.ts?vue&type=script&lang=ts"
export * from "./NotificationSettings.ts?vue&type=script&lang=ts"

import "./notificationSettings.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "50761ba4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('50761ba4', script)) {
    api.reload('50761ba4', script)
  }
  
  module.hot.accept("./NotificationSettings.vue?vue&type=template&id=50761ba4", () => {
    api.rerender('50761ba4', render)
  })

}

script.__file = "src/views/Profile/NotificationSettings.vue"

export default script