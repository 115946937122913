import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import Prompt from 'components/Prompt';
import { ModalHeader, ModalHeaderDirection } from 'components/Modal';
import { TxtWeight } from 'components/Txt';
import { IconName, IconType } from 'components/Icon';
import { Size } from 'core/styles';
import { Api } from 'api/api';
import { useApiErrors } from 'composables/apiErrors';
import { APP_STORE_KEY, AppAction } from 'store/app';
import { RouteNames } from 'router/names';
import DeletionReasonPopup from './components/DeletionReasonPopup';

const PROFILE_DELETE_TIMER = 5;

export default defineComponent({
  name: RouteNames.ProfileSettings,

  components: {
    Prompt,
    ModalHeader,
    DeletionReasonPopup,
  },

  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const store = useStore();
    const { showError } = useApiErrors();

    const deletionReason = ref('');

    const deleteProfilePrompt = ref(false);
    const timer = ref(0);
    let timerId: number | undefined;

    watch(deleteProfilePrompt, (val) => {
      if (val) {
        onPromtOpen();
      } else {
        clearTimer();
      }
    });

    function timerTick() {
      if (--timer.value <= 0) {
        clearTimer();
        deleteProfile();
      } else {
        timerId = window.setTimeout(timerTick, 1000);
      }
    }

    function clearTimer() {
      window.clearInterval(timerId);
      timerId = undefined;
      timer.value = 0;
    }

    function goToProfile() {
      router.push({ name: RouteNames.Profile });
    }

    function onPromtOpen() {
      clearTimer();
      timer.value = PROFILE_DELETE_TIMER;
      timerId = window.setTimeout(timerTick, 1000);
    }

    async function deleteProfile() {
      try {
        const { data } = await Api.userService.deleteProfile(deletionReason.value);

        if (data.result) {
          deleteProfilePrompt.value = false;

          await store.dispatch(`${APP_STORE_KEY}/${AppAction.Logout}`, false);
          window.location.href = '/signup';
        }
      } catch (error) {
        showError(error);
      } finally {
        deleteProfilePrompt.value = false;
      }
    }

    const deleteProfileConfirmationPrompt = ref(false);
    const isDeletionReasonPopupShow = ref(false);

    function handleDeletionReasonSubmit(reason: string) {
      deletionReason.value = reason;
      deleteProfileConfirmationPrompt.value = true;
    }

    const items: Array<{
      iconName: string;
      iconType: string;
      label: string;
      onClick: () => void;
    }> = [
      {
        iconName: IconName.Wallet,
        iconType: IconType.Contour,
        label: t('pages.profile.goToWallet'),
        onClick: () => { router.push({ name: RouteNames.ProfileWallet }); },
      },
      {
        iconName: IconName.Lock,
        iconType: IconType.Contour,
        label: t('pages.profile.changePassword'),
        onClick: () => { router.push({ name: RouteNames.ProfilePasswordChange }); },
      }, /*
      {
        iconName: IconName.Phone,
        iconType: IconType.Contour,
        label: t('pages.profile.changePhoneNumber'),
        onClick: () => { router.push({ name: RouteNames.ProfilePhoneChange }); },
      }, */
      {
        iconName: IconName.Email,
        iconType: IconType.Contour,
        label: t('pages.profile.changeEmail'),
        onClick: () => { router.push({ name: RouteNames.ProfileEmailChange }); },
      },
      {
        iconName: IconName.Bell,
        iconType: IconType.Contour,
        label: t('pages.profile.notificationSettings'),
        onClick: () => { router.push({ name: RouteNames.ProfileNotificationSettings }); },
      },
      {
        iconName: IconName.History,
        iconType: IconType.Contour,
        label: t('pages.profile.authLog'),
        onClick: () => { router.push({ name: RouteNames.AuthLog }); },
      },
      {
        iconName: IconName.Painting,
        iconType: IconType.Contour,
        label: t('pages.profile.changeStyling'),
        onClick: () => { router.push({ name: RouteNames.ProfileSettingsStyling }); },
      },
      {
        iconName: IconName.DeleteAlt,
        iconType: IconType.Contour,
        label: t('pages.profile.deleteProfile'),
        onClick: () => { isDeletionReasonPopupShow.value = true; },
      },
    ];

    return {
      items,
      deleteProfilePrompt,
      deleteProfileConfirmationPrompt,
      isDeletionReasonPopupShow,
      timer,
      goToProfile,
      handleDeletionReasonSubmit,

      Size,
      TxtWeight,
      IconName,
      IconType,
      ModalHeaderDirection,
    };
  },
});
