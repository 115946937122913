import { render } from "./Message.vue?vue&type=template&id=290ec6a6&scoped=true"
import script from "./Message.ts?vue&type=script&lang=ts"
export * from "./Message.ts?vue&type=script&lang=ts"

import "./message.scss?vue&type=style&index=0&id=290ec6a6&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-290ec6a6"
/* hot reload */
if (module.hot) {
  script.__hmrId = "290ec6a6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('290ec6a6', script)) {
    api.reload('290ec6a6', script)
  }
  
  module.hot.accept("./Message.vue?vue&type=template&id=290ec6a6&scoped=true", () => {
    api.rerender('290ec6a6', render)
  })

}

script.__file = "src/views/Profile/components/Message/Message.vue"

export default script