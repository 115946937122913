import { computed, defineComponent } from 'vue';
import Empty from 'components/Empty';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersAction, UsersGetter, UsersMutation } from 'store/users';
import { useFetching } from 'composables/fetching';
import { RouteNames } from 'router/names';
import List from '../components/List';

const UserList = defineComponent({
  name: RouteNames.Catalog,
  components: {
    List,
    Empty,
  },
  setup() {
    const store = useStore();
    const users = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.List}`]);
    const hasNext = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.ListHasNext}`]);

    const { fetch, isFetching } = useFetching();
    function resetFilter() {
      store.commit(`${USER_STORE_KEY}/${UsersMutation.ClearUserList}`);
      store.commit(`${USER_STORE_KEY}/${UsersMutation.ClearFilter}`);
      fetch(store.dispatch(`${USER_STORE_KEY}/${UsersAction.GetCatalog}`));
    }

    function onLoad() {
      fetch(store.dispatch(`${USER_STORE_KEY}/${UsersAction.GetCatalog}`, true), false);
    }
    return {
      users,
      hasNext,
      isFetching,
      resetFilter,
      onLoad,
    };
  },
});

export default UserList;
