import { ClaimType } from 'api/users/models/claims';
import { IProfile, IUser, Sex, User } from 'api/users/models/user';
import { BtnType } from 'components/Btn';
import { IconName, IconType } from 'components/Icon';
import { ModalHeaderDirection } from 'components/Modal';
import { TxtType, TxtWeight } from 'components/Txt';
import ImageSlider from 'components/ImageSlider';
import { MainColor, Size } from 'core/styles';
import { defineComponent, PropType, ref, computed, watch } from 'vue';
import { clamp } from 'ramda';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersAction, UsersGetter } from 'store/users';
import { useI18n } from 'vue-i18n';
import { toastEmitter, ToastType } from 'components/Toasts';
import { useUser } from 'composables/user';
import Prompt from 'components/Prompt';
import { ADMIN_STORE_KEY, AdminAction, AdminGetter } from 'store/admin';
import SettingsBar from 'layouts/UserLayout/components/SettingsBar';
import ToTelegram from 'layouts/UserLayout/components/ToTelegram';
import { Nullable } from 'core/types';
import UnauthorizedPrompt from 'components/UnauthorizedPrompt';
import { CITIES_STORE_KEY, CitiesGetter } from 'store/cities';
import { APP_STORE_KEY, AppAction } from 'store/app';
import Toggler from 'components/Toggler/Toggler.vue';
import { useApiErrors } from 'composables/apiErrors';

const UserLayout = defineComponent({
  components: {
    ImageSlider,
    Prompt,
    SettingsBar,
    UnauthorizedPrompt,
    Toggler,
    ToTelegram,
  },

  props: {
    self: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object as PropType<User>,
      required: true,
    },
    claim: {
      type: Boolean,
      default: false,
    },
    favorite: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['clickFavorite', 'clickEdit', 'openMessanger', 'uploaded', 'update-user'],

  setup(props, { emit }) {
    const { showError } = useApiErrors();
    const { t } = useI18n();
    const store = useStore();
    const { getInterestsString, getActivityString, getUserStatus, getPurposeString } = useUser();
    const profile = computed<Nullable<IProfile>>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]);

    const isAdmin = computed(() => store.getters[`${ADMIN_STORE_KEY}/${AdminGetter.IsAdmin}`]);
    const isUserAdmin = computed(() => !!props.user.is_admin);
    const isAuth = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.IsAuth}`]);
    const shadowBan = ref(props.user.common_info?.shadow_ban || 0);

    watch(shadowBan, async (newVal) => {
      try {
        const user = await store.dispatch(`${ADMIN_STORE_KEY}/${AdminAction.SetShadowBan}`, {
          userId: props.user.id,
          isSet: newVal ? 1 : 0,
        });
        emit('update-user', user);
      } catch (e) {
        showError(e);
      }
    });

    const blockedAtRef = ref(props.user.common_info?.blocked);
    const isBlocking = ref(false);
    async function block() {
      isBlocking.value = true;
      try {
        const user = await store.dispatch(`${ADMIN_STORE_KEY}/${AdminAction.Block}`, {
          userId: props.user.id,
          date: blockedAtRef.value,
        });
        emit('update-user', user);
      } catch (e) {
        showError(e);
      }
      isBlocking.value = false;
    }

    const isDeleted = ref(props.user.common_info?.deleted);
    const isDeleting = ref(false);
    async function deleteToggle() {
      isDeleting.value = true;
      try {
        const newDeleteState = isDeleted.value !== true;
        await store.dispatch(`${ADMIN_STORE_KEY}/${AdminAction.DeleteToggle}`, {
          userId: props.user.id,
          deleting: newDeleteState ? 1 : 0,
        });
        isDeleted.value = newDeleteState;
      } catch (e) {
        showError(e);
      }
      isDeleting.value = false;
    }

    async function addOrRemoveAdmin() {
      try {
        const user = await store.dispatch(`${ADMIN_STORE_KEY}/${AdminAction.AddOrRemoveAdmin}`, props.user.id);
        emit('update-user', user);
      } catch (e) {
        showError(e);
      }
    }

    const isClaimOpen = ref(false);
    const isViewerOpened = ref(false);
    const activeImageIndex = ref(0);
    const fileInput = ref<HTMLInputElement>();

    const claims = Object.values(ClaimType);

    const dotsCount = computed(() => clamp(1, 5, (props.user.photos.length ?? 0) - 1));

    async function handleClaim(type: ClaimType) {
      const data = {
        userId: props.user.id,
        type,
      };
      try {
        store.dispatch(`${USER_STORE_KEY}/${UsersAction.PutClaim}`, data);

        isClaimOpen.value = false;

        toastEmitter.emit('toast', {
          type: ToastType.Success,
          message: t('pages.claimSuccess'),
        });
      } catch (e) {
        showError(e);
      }
    }

    async function onFileInputChange(e: Event) {
      if (!props.self) return;

      const _files = (e.target as HTMLInputElement).files;
      if (_files) {
        emit('uploaded', Array.from(_files));
        if (fileInput.value) {
          fileInput.value.value = '';
        }
      }
    }

    function onPhotoViewerClick() {
      if (props.user.photos.length > 0) {
        isViewerOpened.value = true;
      }
    }

    async function logout() {
      try {
        await store.dispatch(`${APP_STORE_KEY}/${AppAction.Logout}`);
      } catch (e) {
        showError(e);
      }
      window.location.href = '/';
    }

    const isAuthorizedModalShow = ref(false);
    const unauthorizedModalTitle = ref('');
    function handleSendMessage() {
      if (isAuth.value) {
        emit('openMessanger');
      } else {
        isAuthorizedModalShow.value = true;
        unauthorizedModalTitle.value = t('layouts.userLayout.unauthorized.message');
      }
    }
    function handleAddToFavorite() {
      if (isAuth.value) emit('clickFavorite');
      else {
        isAuthorizedModalShow.value = true;
        unauthorizedModalTitle.value = t('layouts.userLayout.unauthorized.like');
      }
    }

    function getUserCityName(user: IUser) {
      const city = store.getters[`${CITIES_STORE_KEY}/${CitiesGetter.CityById}`](user.city_id);
      return city?.name || '';
    }

    function checkPropertyExist(property: any) {
      if (!property) return false;
      if (Array.isArray(property)) return property.length;
      return true;
    }

    const paidParamKeys = computed(() => props.user.paid_params.map((p) => (typeof p === 'string' ? p : p?.key)));

    return {
      isAuth,
      isAuthorizedModalShow,
      unauthorizedModalTitle,
      profile,
      isAdmin,
      paidParamKeys,
      isUserAdmin,
      getUserCityName,
      addOrRemoveAdmin,
      handleSendMessage,
      handleAddToFavorite,
      checkPropertyExist,

      getUserStatus,
      getActivityString,
      getInterestsString,
      getPurposeString,

      fileInput,
      activeImageIndex,
      isClaimOpen,
      isViewerOpened,

      claims,

      dotsCount,

      handleClaim,

      onFileInputChange,
      onPhotoViewerClick,

      logout,

      TxtType,
      TxtWeight,
      Sex,
      IconType,
      IconName,
      Size,
      BtnType,
      MainColor,
      ModalHeaderDirection,

      shadowBan,

      block,
      blockedAtRef,
      isBlocking,

      isDeleting,
      isDeleted,
      deleteToggle,
    };
  },
});

export default UserLayout;
