import { render } from "./WalletTransaction.vue?vue&type=template&id=416563e2"
import script from "./WalletTransaction.ts?vue&type=script&lang=ts"
export * from "./WalletTransaction.ts?vue&type=script&lang=ts"

import "./walletTransaction.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "416563e2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('416563e2', script)) {
    api.reload('416563e2', script)
  }
  
  module.hot.accept("./WalletTransaction.vue?vue&type=template&id=416563e2", () => {
    api.rerender('416563e2', render)
  })

}

script.__file = "src/views/Profile/components/WalletTransaction/WalletTransaction.vue"

export default script