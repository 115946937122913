<template>
  <div
    v-if="users.length === 0"
    class="success-users-note"
  >
    <icon
      :name="IconName.Favorites"
      :type="IconType.Big"
    />
    <span class="success-users-note__text">{{ $t('pages.successUsers.note') }}</span>
    <btn
      w="100%"
      class="success-users-note__btn"
      @click="goToCatalog"
    >
      {{ $t('pages.successUsers.btnViewCatalog') }}
    </btn>
  </div>
  <list
    v-else
    :has-next="hasNext"
    :is-fetching="isFetching"
    :users="users"
    @load="onLoad"
  />
</template>

<script lang="ts" src="./SuccessUsers.ts" />
<style lang="scss" src="./successUsers.scss" />
