import { IProfile, IUser } from 'api/users/models/user';
import { Nullable } from 'core/types';
import MainLayout from 'layouts/MainLayout';
import UserLayout from 'layouts/UserLayout';
import { USER_STORE_KEY, UsersAction, UsersGetter, useUsersGetter } from 'store/users';
import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useFetching } from 'composables/fetching';
import { useI18n } from 'vue-i18n';
import { useApiErrors } from 'composables/apiErrors';
import { RouteNames } from 'router/names';
import { useFavorites } from 'composables/favorites';
import InactiveUser from './InactiveUser.vue';

const User = defineComponent({
  name: RouteNames.User,
  components: {
    MainLayout,
    UserLayout,
    InactiveUser,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { id } = route.params;
    const { showError } = useApiErrors();

    const { isFetching, fetch } = useFetching();

    const user = ref<Nullable<IUser>>(null);
    watch(() => user.value?.name, (name) => {
      if (name) document.title = name;
      else document.title = t('pageTitles.room');
    });
    const profile = useUsersGetter<Nullable<IProfile>>(UsersGetter.Profile);
    const { isFavoriteUser } = useFavorites();
    const isFavorite = computed(() => isFavoriteUser(user.value));

    async function updateUser(updatedUser?: IUser) {
      try {
        if (updatedUser) user.value = updatedUser;
        else user.value = await fetch(store.dispatch(`${USER_STORE_KEY}/${UsersAction.GetUserById}`, { id }));
      } catch (e) {
        showError(e);
      }
    }

    onBeforeMount(async () => {
      if (profile?.id.toString() === id) {
        router.push({ name: 'profile' });
        return;
      }
      try {
        user.value = await fetch(store.dispatch(`${USER_STORE_KEY}/${UsersAction.GetUserById}`, { id }));
      } catch (e) {
        showError(e);
      }
    });
    const { toggleFavorite } = useFavorites();
    const handleFavorite = () => {
      toggleFavorite(user);
    };

    const handleOpenMessages = () => {
      if (route.query.to === 'profile') {
        router.back();
        return;
      }

      router.push({ name: 'room', params: { id: id as string } });
    };

    const isUserActive = computed(() => profile?.is_admin || !(user.value?.blocked_at || user.value?.deleted_at));
    let inactiveUserStateTitle = ref('');
    if (user.value?.blocked_at) {
      inactiveUserStateTitle = ref('Пользователь заблокирован');
    }

    return {
      isFetching,
      isFavorite,
      user,
      isUserActive,
      inactiveUserStateTitle,

      handleOpenMessages,
      handleFavorite,
      updateUser,
    };
  },
});

export default User;
