<template>
  <div class="notification-settings">
    <modal-header
      :title="$t('pages.profile.notifications.title')"
      :dir="ModalHeaderDirection.Ltr"
      relative
      @close="back"
    >
      <template #close-icon>
        <icon
          :type="IconType.Contour"
          :name="IconName.ArrowBack"
          :size="Size.S"
        />
      </template>
    </modal-header>

    <div class="notification-settings__content">
      <div class="notification-settings__option">
        <h4 class="notification-settings__option-title">{{ $t('pages.profile.notifications.unreadMessages') }}</h4>
        <toggler v-model="unreadMessages" class="notification-settings__toggler" />
      </div>

      <div class="notification-settings__option">
        <h4 class="notification-settings__option-title">{{ $t('pages.profile.notifications.userActions') }}</h4>
        <toggler v-model="userActions" class="notification-settings__toggler" />
      </div>

      <div class="notification-settings__option">
        <h4 class="notification-settings__option-title">{{ $t('pages.profile.notifications.payments') }}</h4>
        <toggler v-model="payments" class="notification-settings__toggler" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./NotificationSettings.ts" />
<style lang="scss" src="./notificationSettings.scss" />
