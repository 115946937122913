<template>
  <div
    :class="['message', type]"
    :style="{ backgroundColor: `var(--${backgroundColor})` }"
  >
    <txt>
      {{ t(`pages.profile.styling.${type}MessageText`) }}
    </txt>
  </div>
</template>

<script lang="ts" src="./Message.ts"></script>
<style lang="scss" scoped src="./message.scss"></style>
