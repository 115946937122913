import { render } from "./SuccessEmailConfirm.vue?vue&type=template&id=17a7e755"
import script from "./SuccessEmailConfirm.ts?vue&type=script&lang=ts"
export * from "./SuccessEmailConfirm.ts?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "17a7e755"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('17a7e755', script)) {
    api.reload('17a7e755', script)
  }
  
  module.hot.accept("./SuccessEmailConfirm.vue?vue&type=template&id=17a7e755", () => {
    api.rerender('17a7e755', render)
  })

}

script.__file = "src/views/SuccessEmailConfirm/SuccessEmailConfirm.vue"

export default script