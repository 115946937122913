import { render } from "./EditForm.vue?vue&type=template&id=521a3216"
import script from "./EditForm.ts?vue&type=script&lang=ts"
export * from "./EditForm.ts?vue&type=script&lang=ts"

import "./edit-form.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "521a3216"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('521a3216', script)) {
    api.reload('521a3216', script)
  }
  
  module.hot.accept("./EditForm.vue?vue&type=template&id=521a3216", () => {
    api.rerender('521a3216', render)
  })

}

script.__file = "src/views/Profile/components/EditForm/EditForm.vue"

export default script