import { render } from "./User.vue?vue&type=template&id=768dc365"
import script from "./User.ts?vue&type=script&lang=ts"
export * from "./User.ts?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "768dc365"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('768dc365', script)) {
    api.reload('768dc365', script)
  }
  
  module.hot.accept("./User.vue?vue&type=template&id=768dc365", () => {
    api.rerender('768dc365', render)
  })

}

script.__file = "src/views/User/User.vue"

export default script